import { Grid, Select } from '@mantine/core';

import { SearchInput } from 'components';
import {
  SkuStatus,
  getEmptyOption,
  CulinarySkusRequest,
  STATE_OPTIONS,
  STATE_DROPDOWN,
} from 'shared';
import { useCategories } from 'pages/products';

interface Props {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  values: CulinarySkusRequest;
}

export const Filters = ({ handleChange, values }: Props): JSX.Element => {
  const { search_string, category, status } = values;

  const { categoryNestedOptions } = useCategories();

  return (
    <Grid>
      <Grid.Col span={3}>
        <Select
          label="Category"
          value={category}
          searchable
          nothingFound="No options"
          data={[getEmptyOption('All Categories'), ...categoryNestedOptions]}
          onChange={(v) => handleChange({ category: v ?? '' })}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Select
          searchable
          label="State"
          nothingFound="No options"
          value={status}
          data={STATE_OPTIONS}
          data-testid={STATE_DROPDOWN}
          onChange={(v: SkuStatus) => handleChange({ status: v || '' })}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label="Search"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
