import { FC, useEffect } from 'react';
import {
  TextInput,
  Container,
  Select,
  Grid,
  Switch,
  Flex,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { propOr } from 'rambda';

import { useForm, yupResolver } from '@mantine/form';
import { useRoles } from 'hooks';
import { useLocations } from 'App/contexts';
import { ActionButtons } from 'components';
import {
  defaultSelectOptions,
  EmployeeFormValues,
  Permissions,
  hasPOSAccess,
  LOCATION_DROPDOWN,
  EMAIL_INPUT,
  LAST_NAME_INPUT,
  FIRST_NAME_INPUT,
} from 'shared';
import { employeeFormSchema } from './schema';

export type EmployeeFormType = {
  handleSubmit: (values: EmployeeFormValues) => void;
  initialValues: EmployeeFormValues;
  canBeActivated?: boolean;
  children?: React.ReactNode;
};

export const Form: FC<EmployeeFormType> = ({
  handleSubmit,
  initialValues,
  canBeActivated = false,
  children,
}): JSX.Element => {
  const { setFieldValue, ...form } = useForm({
    initialValues,
    validate: yupResolver(employeeFormSchema),
    validateInputOnBlur: true,
  });

  const selectedLocation = form.values.primary_location_id;
  const selectedRole = form.values.role_id;
  const hasPosAccess = form.values.hasPosAccess;

  const { options: locationOptions } = useLocations();
  const { options: rolesOptions, findRoleById } = useRoles(selectedLocation);

  useEffect(() => {
    const permissions = propOr([], 'permissions', findRoleById(selectedRole));
    setFieldValue('hasPosAccess', hasPOSAccess(permissions as Permissions[]));
  }, [selectedRole, findRoleById, setFieldValue]);

  return (
    <Container size={'sm'}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter={'xl'}>
          <Grid.Col span={6}>
            <TextInput
              required
              data-testid={FIRST_NAME_INPUT}
              label="First Name"
              {...form.getInputProps('first_name')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required
              data-testid={LAST_NAME_INPUT}
              label="Last Name"
              {...form.getInputProps('last_name')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              required
              data-testid={EMAIL_INPUT}
              label="Email"
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <DatePickerInput
              required
              label="Start Date"
              data-testid="start-date-datepicker"
              {...form.getInputProps('start_date')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Select
              required
              label="Primary Location"
              data-testid={LOCATION_DROPDOWN}
              data={[defaultSelectOptions, ...(locationOptions || [])]}
              {...form.getInputProps('primary_location_id')}
              onChange={(v) => {
                setFieldValue('primary_location_id', v ?? '');
                setFieldValue('role_id', '');
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Select
              data-testid="role-dropdown"
              required
              disabled={!rolesOptions?.length}
              label="Role"
              data={[defaultSelectOptions, ...(rolesOptions || [])]}
              {...form.getInputProps('role_id')}
            />
          </Grid.Col>
          {hasPosAccess && (
            <Grid.Col span={12}>
              <TextInput
                data-testid="pos-code-input"
                required
                label="POS Passcode"
                {...form.getInputProps('passcode')}
              />
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <Flex gap="xl">
              <Switch
                checked={form.values.can_be_approver}
                data-testid="supervisor-or-approval-pos-switch"
                label="Supervisor/Approval for POS"
                labelPosition="right"
                sx={{ fontWeight: 600 }}
                {...form.getInputProps('can_be_approver', { type: 'checkbox' })}
              />
              {canBeActivated && (
                <Switch
                  label="Active"
                  labelPosition="right"
                  sx={{ fontWeight: 600 }}
                  {...form.getInputProps('active', { type: 'checkbox' })}
                />
              )}
            </Flex>
          </Grid.Col>

          <Grid.Col span={12}>
            <ActionButtons
              disabled={!form.isValid()}
              type="submit"
              label="Save"
              aria-label="save"
              secondary={children}
            />
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
