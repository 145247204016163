import { Link } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { Form } from '../forms';

// import { useCreateTiers } from '../../hooks';

type Props = {
  rootPath: string;
};

const title = 'New Wine Club Customer Group';

export const Create = ({ rootPath }: Props) => {
  // const {} = useCreateTiers();

  return (
    <ContentLayout
      title={title}
      processing={false}
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <Form />
    </ContentLayout>
  );
};
