import * as Yup from 'yup';
import {
  requiredStringValidationRule,
  requiredNumberValidationRule,
} from 'shared';

const dateValidation = Yup.string()
  .nullable()
  .matches(/^(0[1-9]|1[0-2]).(0[1-9]|[12]\d|3[01]).(19|20)\d{2}$/, {
    excludeEmptyString: true,
    message: 'Incorrect Date',
  });

export const discountSchema = Yup.object({
  name: requiredStringValidationRule('Promocode Name'),
  is_active: Yup.boolean(),
  hide_from_ecommerce: Yup.boolean(),
  value: Yup.number()
    .required('Value is required')
    .when('free_shipping', {
      is: false,
      then: () => requiredNumberValidationRule('Value'),
    }),
  unit: Yup.string(),
  start_date: dateValidation,
  end_date: dateValidation,
  location_ids: Yup.array(requiredStringValidationRule('Primary Location')),
  category_id: Yup.string().nullable(),
  subcategory_id: Yup.string().nullable(),
  is_club_tier: Yup.boolean(),
  club_tier_id: Yup.string()
    .nullable()
    .when('is_club_tier', {
      is: true,
      then: () => requiredStringValidationRule('Wine Club Tier').nullable(),
    }),
  minimum_total: Yup.number()
    .min(0)
    .notRequired()
    .typeError('Min Total ($) Must be a number'),
  minimum_quantity: Yup.number()
    .min(0)
    .notRequired()
    .typeError('Min Qty (Pc) Must be a number'),
});
