import { useQueryClient } from '@tanstack/react-query';

import { useApiQuery } from 'hooks';
import { CustomerService } from 'App/api';

const CUSTOMER_KEY = 'customer';

export const useCustomer = (id: string) => {
  const { data, isLoading, isError, isFetching } = useApiQuery(
    [CUSTOMER_KEY, id],
    () => CustomerService.getOne({ id }),
    {
      cacheTime: 0,
    },
  );

  const client = useQueryClient();

  const refetch = () => {
    client.invalidateQueries([CUSTOMER_KEY, id]);
  };

  return {
    data,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};
