import { useMemo } from 'react';
import { getSelectOptions, getMargin } from 'shared';
import { useCategories } from 'pages/products';

type TPayload = {
  categoryId: string | null;
  price?: string | null;
  cost?: number;
  btgPrice?: string | null;
  btgCost?: number | null;
};

export const useInitialForm = (payload: TPayload) => {
  const { categories, categoryOptions } = useCategories();
  const { categoryId, cost, price } = payload;

  const subcategoryOptions = useMemo(() => {
    const category = categories.find((c) => categoryId === c.id);
    return getSelectOptions(category?.subcategories ?? []);
  }, [categories, categoryId]);

  const margin = useMemo(() => getMargin(price, cost), [cost, price]);

  return {
    categoryOptions,
    subcategoryOptions,
    margin,
  };
};
