import { Grid, Select } from '@mantine/core';
import { useClubTier } from 'hooks';

export const CustomerGroup = () => {
  const { options: tierOptions } = useClubTier({});

  return (
    <>
      <Grid.Col>
        <Select
          required
          data={['Tier', 'Add-on']}
          label="Select customer group"
        />
      </Grid.Col>
      <Grid.Col>
        <Select required data={tierOptions} label="Base Tier" />
      </Grid.Col>
    </>
  );
};
