import { Grid, Select, Switch, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { openModal } from '@mantine/modals';

import { useLocations } from 'App/contexts';
import {
  ClubMembershipValues,
  FREIGHT_COMPANY,
  FREIGHT_COMPANY_DROPDOWN,
  FREIGHT_METHOD,
  FREIGHT_METHOD_DROPDOWN,
  PICKUP_SWITCH,
  SelectOption,
  SHIPMENT_SWITCH,
} from 'shared';
import { ConfirmData } from 'components';
import { getChangeInformationMessage } from 'pages/crm/customers/helpers';
import {
  DELIVERY_METHOD,
  PICKUP_LOCATION,
  SHIPPING_ADDRESS,
} from 'pages/crm/customers/constants';

type Props = {
  form: UseFormReturnType<ClubMembershipValues>;
  isEdit?: boolean;
  isUnpaid?: boolean;
  isShipment?: boolean;
  updateOrders?: boolean;
  setUpdateOrders?: React.Dispatch<React.SetStateAction<boolean>>;
  addressOptions?: SelectOption[];
};

const showConfirmWindow = ({
  message,
  action,
  reject,
}: {
  message: string;
  action: () => void;
  reject?: () => void;
}) => {
  return openModal({
    size: 'lg',
    title: (
      <Text size="md" weight={600}>
        Update Upcoming Order?
      </Text>
    ),
    children: (
      <ConfirmData message={message} approve={action} reject={reject} />
    ),
  });
};

export const ClubMemberDeliveryFields = ({
  form,
  isEdit = false,
  isUnpaid = false,
  isShipment = false,
  updateOrders = false,
  setUpdateOrders = () => null,
  addressOptions = [],
}: Props): JSX.Element => {
  const { actualLocationOptions } = useLocations();
  const { values, setFieldValue } = form;
  const isPickup = values.isPickup;
  const showShipmentFields = isEdit && !isPickup;

  const clearShipment = () => {
    setFieldValue('freight_company', undefined);
    setFieldValue('freight_method', undefined);
  };

  const clearPickup = () => {
    setFieldValue('pickup.location_id', undefined);
  };

  return (
    <Grid gutter="xl">
      <Grid.Col mt="md" pb={0}>
        <Text size={18} weight={700}>
          Delivery Method
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Grid gutter={'xl'}>
          <Grid.Col span={3}>
            <Switch
              checked={!values.isPickup}
              onChange={(e) => {
                clearShipment();

                if (isUnpaid && e.currentTarget.checked) {
                  showConfirmWindow({
                    message: getChangeInformationMessage(DELIVERY_METHOD),
                    action: () => {
                      setFieldValue('isPickup', false);
                      setUpdateOrders(true);
                    },
                    reject: () => {
                      setFieldValue('isPickup', false);
                      setUpdateOrders(false);
                    },
                  });
                } else {
                  setFieldValue('isPickup', false);
                  setUpdateOrders(false);
                }
              }}
              label="Shipment"
              data-testid={SHIPMENT_SWITCH}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Switch
              {...form.getInputProps('isPickup', { type: 'checkbox' })}
              label="Pickup"
              data-testid={PICKUP_SWITCH}
              onChange={(e) => {
                clearPickup();

                if (isUnpaid && e.currentTarget.checked) {
                  showConfirmWindow({
                    message: getChangeInformationMessage(DELIVERY_METHOD),
                    action: () => {
                      setFieldValue('isPickup', true);
                      setUpdateOrders(true);
                    },
                    reject: () => {
                      setFieldValue('isPickup', true);
                      setUpdateOrders(false);
                    },
                  });
                } else {
                  setFieldValue('isPickup', true);
                }
              }}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {values.isPickup && (
        <Grid.Col span={6}>
          <Select
            required
            label="Location"
            data-testid="pickup-location-dropdown"
            data={actualLocationOptions}
            {...form.getInputProps('pickup.location_id')}
            onChange={(e) => {
              if (
                isUnpaid &&
                !updateOrders &&
                !isShipment &&
                e !== values.pickup?.location_id
              ) {
                showConfirmWindow({
                  message: getChangeInformationMessage(PICKUP_LOCATION),
                  action: () => {
                    setFieldValue('pickup.location_id', e);
                    setUpdateOrders(true);
                  },
                  reject: () => {
                    setFieldValue('pickup.location_id', e);
                    setUpdateOrders(false);
                  },
                });
              } else {
                setFieldValue('pickup.location_id', e);
              }
            }}
          />
        </Grid.Col>
      )}
      {showShipmentFields && (
        <>
          <Grid.Col span={6}>
            <Select
              required
              label="Shipping Address"
              data={addressOptions ?? []}
              {...form.getInputProps('shipment.address_id')}
              onChange={(e) => {
                if (
                  isUnpaid &&
                  !updateOrders &&
                  isShipment &&
                  e !== values.shipment?.address_id
                ) {
                  showConfirmWindow({
                    message: getChangeInformationMessage(SHIPPING_ADDRESS),
                    action: () => {
                      setFieldValue('shipment.address_id', e);
                      setUpdateOrders(true);
                    },
                    reject: () => {
                      setFieldValue('shipment.address_id', e);
                      setUpdateOrders(false);
                    },
                  });
                } else {
                  setFieldValue('shipment.address_id', e);
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={6} />
        </>
      )}
      {!isPickup && (
        <>
          <Grid.Col span={6}>
            <Select
              required
              label="Freight Company"
              data={FREIGHT_COMPANY}
              data-testid={FREIGHT_COMPANY_DROPDOWN}
              {...form.getInputProps('freight_company')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              label="Freight Method"
              data={FREIGHT_METHOD}
              data-testid={FREIGHT_METHOD_DROPDOWN}
              {...form.getInputProps('freight_method')}
            />
          </Grid.Col>
        </>
      )}
    </Grid>
  );
};
