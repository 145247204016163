import { Stepper, Button, Group } from '@mantine/core';

import {
  useCreateShipmentCycle,
  useSuggestions,
  useWineSelection,
} from '../../hooks';
import { Steps } from '../../constants';
import { useForm, yupResolver } from '@mantine/form';
import { cycleSettingSchema } from './schemas';
import { IStepper, ShipmentCycleDto, ShippingDetails } from 'shared';
import { getNewSkuItem, getNewSuggestionItem } from '../../helpers';
import { CycleSettingForm } from './CycleSettingForm';
import { WineSelectionForm } from './WineSelectionForm';
import { ShippingDetailsForm } from './ShippingDetailsForm';
import { SuggestionsForm } from './SuggestionsForm';

const initialSettingsValues: ShipmentCycleDto = {
  name: '',
  club_tier_id: '',
  cycle_start_date: null,
  member_list_close_date: null,
  end_of_customization_window: null,
  billing_date: null,
  re_billing_date: null,
  estimated_shipping_date: null,
  pick_up_deadline: null,
  wine_selections: [],
  suggestions: [],
};

const shippingDetailsValues: ShippingDetails = {
  freight_company: null,
  freight_method: null,
};

const wineSelectionInitialValues = {
  wine_selections: [getNewSkuItem({})],
};
const suggestionsInitialValues = {
  suggestions: [getNewSuggestionItem({})],
};

export const CreateForm = ({ onExit }: { onExit: () => void }) => {
  const { activeStep, setActiveStep, tierOptions, stepProcessing, isLoading } =
    useCreateShipmentCycle({ onExit });

  const cycleSettingForm = useForm({
    initialValues: initialSettingsValues,
    validate: yupResolver(cycleSettingSchema),
    validateInputOnBlur: true,
  });

  const wineSelectionForm = useForm({
    initialValues: wineSelectionInitialValues,
  });

  const suggestionsForm = useForm({
    initialValues: suggestionsInitialValues,
  });

  const shippingDetailsForm = useForm({
    initialValues: shippingDetailsValues,
  });

  const tierId = cycleSettingForm.values.club_tier_id;

  const { tier } = useWineSelection({
    tierId,
    setValues: wineSelectionForm.setFieldValue,
  });

  const { tiers } = useSuggestions({
    tierId,
    setValues: suggestionsForm.setFieldValue,
  });

  const steps: IStepper<Steps>[] = [
    {
      label: Steps.CycleSetting,
      component: (
        <CycleSettingForm form={cycleSettingForm} tierOptions={tierOptions} />
      ),
    },
    {
      label: Steps.WineSelection,
      component: <WineSelectionForm tier={tier} form={wineSelectionForm} />,
    },
    {
      label: Steps.Suggestions,
      component: <SuggestionsForm tiers={tiers} form={suggestionsForm} />,
    },
    {
      label: Steps.ShippingDetails,
      component: <ShippingDetailsForm form={shippingDetailsForm} />,
    },
  ];

  const isLastStep = activeStep === steps.length - 1;
  const isInvalid = activeStep === 0 && !cycleSettingForm.isValid();

  return (
    <>
      <Stepper active={activeStep} onStepClick={setActiveStep}>
        {steps.map(({ label, component }) => (
          <Stepper.Step key={label} label={label} disabled={isInvalid}>
            {component}
          </Stepper.Step>
        ))}
      </Stepper>

      <Group position="center" mt={48}>
        <Button
          sx={{ width: 540 }}
          disabled={isInvalid}
          loading={isLoading}
          onClick={() =>
            stepProcessing({
              step: steps[activeStep].label,
              cycleSetting: cycleSettingForm.values,
              wineSelection: wineSelectionForm.values,
              suggestion: suggestionsForm.values,
              shippingDetails: shippingDetailsForm.values,
            })
          }
        >
          {isLastStep ? 'Save as a draft' : 'Next'}
        </Button>
      </Group>
    </>
  );
};
