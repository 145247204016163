import { InventoryType } from '../inventory';
import { BottleQuantity, WineType } from '../common';
import { LocationTypes } from '../settings';

export enum DiscountUnit {
  dollar = '$',
  percentage = '%',
}

export interface DiscountDto {
  id: string;
  name: string;
  is_active: boolean;
  value: number;
  unit: DiscountUnit;
  hide_from_ecommerce: boolean;
  description: string;
  comment: string;
  start_date?: string | null;
  end_date?: string | null;
  inventory_type?: InventoryType | null;
  minimum_quantity?: number | null;
  minimum_total?: number | null;
  applied_to_glass: boolean;
  free_shipping: boolean;
  one_time_promotion: boolean;
  is_used: boolean;
  category: {
    id: string;
    name: string;
    parent: {
      id: string;
      name: string;
    };
  };
  club_tier: {
    id: string;
    name: string;
    status: string;
    level: string;
    show_in_ecommerce: boolean;
    wine_types_allowed: WineType[];
    bottle_quantity_allowed: BottleQuantity[];
  } | null;
  locations: {
    id: string;
    name: string;
    type: LocationTypes;
  }[];
}

export interface CreateDiscount
  extends Omit<DiscountDto, 'category' | 'locations' | 'club_tier' | 'id'> {
  location_ids: string[];
  category_id: string | null;
  subcategory_id: string | null;
  club_tier_id: string | null;
  is_club_tier?: boolean;
}
