import { useState } from 'react';
import { Button, Container, Grid, Group, Stepper, Text } from '@mantine/core';
import { Steps } from '../../constants';
import { IStepper } from 'shared';
import { CustomerGroup } from './CustomerGroup';

export const Form = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const steps: IStepper<Steps>[] = [
    {
      label: Steps.CUSTOMER_GROUP,
      component: <CustomerGroup />,
    },
    {
      label: Steps.CYCLE_PREFERENCES,
      component: <></>,
    },
    {
      label: Steps.DISCOUNTS,
      component: <></>,
    },
    {
      label: Steps.COMPLEMENTARY_TASTINGS,
      component: <></>,
    },
    {
      label: Steps.SHIPPING_SETTINGS,
      component: <></>,
    },
    {
      label: Steps.PENALTIES,
      component: <></>,
    },
  ];

  const isLastStep = activeStep === steps.length - 1;

  return (
    <form onSubmit={() => null}>
      <Stepper
        style={{ maxWidth: 900, margin: '0 auto', position: 'relative' }}
        styles={{
          stepBody: { display: 'none' },
          separator: { marginLeft: -16, marginRight: -16 },
        }}
        active={activeStep}
        onStepClick={setActiveStep}
      >
        {steps.map(({ label, component }) => (
          <Stepper.Step key={label}>
            <Grid
              gutter="md"
              style={{ width: 1050, position: 'absolute', left: -70 }}
            >
              {steps.map(({ label }) => (
                <Grid.Col key={label} span={2}>
                  <Text align="center" weight={600}>
                    {label}
                  </Text>
                </Grid.Col>
              ))}
            </Grid>
            <Grid gutter="xl" mt={60}>
              {component}
            </Grid>
          </Stepper.Step>
        ))}
      </Stepper>
      <Container size="xs" mt={40}>
        <Group>
          {isLastStep ? (
            <>
              <Button fullWidth>Save as Draft</Button>
              <Button fullWidth variant="outline">
                Save
              </Button>
            </>
          ) : (
            <Button
              fullWidth
              onClick={() => setActiveStep((prevStep) => prevStep + 1)}
            >
              Next
            </Button>
          )}
        </Group>
      </Container>
    </form>
  );
};
