import { useApiQuery } from 'hooks';
import { Employee, normalizeEmployee, EmployeeOutput } from 'shared';
import { EmployeeService } from 'App/api';

export const employeeKey = 'employee';

export const useEmployee = (
  employeeId: string,
): {
  data?: Employee;
  isLoading: boolean;
  isError: boolean;
  output: EmployeeOutput | undefined;
} => {
  const { data, isLoading, isError } = useApiQuery(
    [employeeKey, employeeId],
    () => EmployeeService.getEmployee({ employeeId }),
    {
      enabled: !!employeeId,
    },
  );

  return {
    data,
    isLoading,
    isError,
    output: data && normalizeEmployee(data),
  };
};
