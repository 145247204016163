import {
  Flex,
  Grid,
  LoadingOverlay,
  Pagination,
  Paper,
  Text,
} from '@mantine/core';
import ReactJson from 'react-json-view';
import { ActionButtons } from 'components';
import { useChangeHistory } from 'hooks';

type Props = {
  orderId: string;
  goBack: () => void;
};

export const ChangeHistory = ({ goBack, orderId }: Props) => {
  const { data, page, totalRecords, isLoading, setPage } = useChangeHistory({
    orderId,
  });

  return (
    <Paper p="lg" mt="md" sx={{ position: 'relative' }}>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />

      <Grid gutter="xl">
        <Grid.Col>
          <Text size={18} weight={700} mb={25}>
            Change History
          </Text>
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            src={data?.items ?? []}
            theme="rjv-default"
          />
          <Flex justify="flex-end" mt={25}>
            <Pagination
              value={page}
              onChange={setPage}
              total={totalRecords / 10}
              disabled={!Math.floor(totalRecords / 10)}
            />
          </Flex>
        </Grid.Col>

        <Grid.Col mt={10}>
          <ActionButtons
            variant="subtle"
            label="Back to Order"
            onClick={goBack}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
