import {
  Button,
  Container,
  Grid,
  Select,
  SelectItem,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { DatePickerInput, DateTimePicker } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import {
  DATE_FORMAT_SLASH,
  DATE_TIME_FORMAT_SLASH,
  ShipmentCycleDto,
} from 'shared';

export type Props = {
  form: UseFormReturnType<ShipmentCycleDto>;
  isEdit?: boolean;
  processing?: boolean;
  isStartAfterSave?: boolean;
  tierOptions: SelectItem[];
  handleSubmit?: (values: ShipmentCycleDto) => void;
  setIsInventoryCheck?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStartAfterSave?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CycleSettingForm = ({
  form,
  isEdit,
  tierOptions,
  isStartAfterSave = false,
  setIsInventoryCheck = () => null,
  setIsStartAfterSave = () => null,
}: Props) => {
  const fullWidth = { maxWidth: '100%' };

  return (
    <Container size="xl">
      <form>
        <Grid gutter="xl">
          <Grid.Col span={6}>
            <Select
              required
              disabled={isEdit}
              sx={fullWidth}
              data={tierOptions}
              label="Club Membership Tier"
              {...form.getInputProps('club_tier_id')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required
              sx={fullWidth}
              label="Cycle Name"
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          {isEdit && (
            <>
              <Grid.Col span={12}>
                <Button
                  variant="white"
                  onClick={() => setIsInventoryCheck(true)}
                >
                  See Inventory Check Details
                </Button>
              </Grid.Col>
              <Grid.Col span={12}>
                <Switch
                  label="Start Cycle After Saving "
                  onChange={(e) => setIsStartAfterSave(e.currentTarget.checked)}
                />
              </Grid.Col>
            </>
          )}

          <Grid.Col span={6}>
            <DateTimePicker
              label="Cycle Start Date Time"
              required={isEdit}
              disabled={isStartAfterSave}
              sx={fullWidth}
              placeholder={DATE_TIME_FORMAT_SLASH}
              valueFormat={DATE_TIME_FORMAT_SLASH}
              {...form.getInputProps('cycle_start_date')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DatePickerInput
              placeholder="mm/dd/yyyy"
              label="Member List Close Date"
              required={isEdit}
              sx={fullWidth}
              valueFormat={DATE_FORMAT_SLASH}
              {...form.getInputProps('member_list_close_date')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DatePickerInput
              placeholder="mm/dd/yyyy"
              label="End of Customization Window"
              required={isEdit}
              sx={fullWidth}
              valueFormat={DATE_FORMAT_SLASH}
              {...form.getInputProps('end_of_customization_window')}
            />
          </Grid.Col>
          <Grid.Col span={12} mt={48}>
            <Text weight={600} size="lg">
              Shipments Scheduler
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <DateTimePicker
              label="Billing Date Time"
              required={isEdit}
              sx={fullWidth}
              placeholder={DATE_TIME_FORMAT_SLASH}
              valueFormat={DATE_TIME_FORMAT_SLASH}
              {...form.getInputProps('billing_date')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateTimePicker
              label="Re-Billing Date Time"
              required={isEdit}
              sx={fullWidth}
              placeholder={DATE_TIME_FORMAT_SLASH}
              valueFormat={DATE_TIME_FORMAT_SLASH}
              {...form.getInputProps('re_billing_date')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DatePickerInput
              placeholder="mm/dd/yyyy"
              label="Estimated Shipment Date"
              required={isEdit}
              valueFormat={DATE_FORMAT_SLASH}
              {...form.getInputProps('estimated_shipping_date')}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <DatePickerInput
              placeholder="mm/dd/yyyy"
              label="Pick-Up Deadline"
              required={isEdit}
              sx={fullWidth}
              valueFormat={DATE_FORMAT_SLASH}
              {...form.getInputProps('pick_up_deadline')}
            />
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
