import {
  DeliveryMethod,
  FreightCompany,
  FreightMethod,
  OrderSource,
  OrderStatus,
  SupplyType,
} from '../baseOrder';
import {
  PaginatedRequest,
  WineType,
  BottleQuantity,
  SelectOption,
} from '../common';
import {
  ClubMembershipChangeReason,
  OnHoldType,
  OrderType,
  ServiceClass,
} from '../membersClub';
import { ClubTierLevel } from './clubTier';

export enum ErrorTopic {
  profile = 'profile',
  addresses = 'addresses',
  wallet = 'wallet',
  club_membership = 'club_membership',
}

export enum AccountGroup {
  WINE_CLUB_MEMBER = 'wine_club_member',
  REGISTERED_CUSTOMER = 'registered_customer',
  UNREGISTERED_CUSTOMER = 'unregistered_customer',
  EMPLOYEE = 'employee',
}

export const accountGroupLabels: Record<AccountGroup, string> = {
  [AccountGroup.WINE_CLUB_MEMBER]: 'Wine Club Member',
  [AccountGroup.REGISTERED_CUSTOMER]: 'Registered Customer',
  [AccountGroup.EMPLOYEE]: 'Employee',
  [AccountGroup.UNREGISTERED_CUSTOMER]: 'Unregistered Customer',
};

type Location = {
  id: string;
  name: string;
  type: string;
};

export type TastingHistory = {
  id: string;
  number: string;
  closed_at: Date;
  quantity: number;
  location: Location;
};

export type TastingLimit = {
  redeemed: number;
  available: number;
  limit: number;
};

type Customer = {
  first_name: string | null;
  last_name: string | null;
};

export type Address = {
  country: string | null;
  state: string | null;
  city: string | null;
  business: string | null;
  zip_code: string | null;
  street_address_one: string | null;
  street_address_two: string | null;
};

export interface EditBillingAddress extends Address, Customer {
  idCard: string;
  customerId: string;
  cardName: string;
}

export interface GeneralDto {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  customer_source_id: string | null;
  date_of_birth: Date | string | null;
  lifetime_value?: number | string;
  preferred_primary_location: {
    id: string;
    name: string;
    type: string;
  } | null;
  secondary_location: { id: string; name: string } | null;
  email_opt_in: boolean;
  sms_opt_in: boolean;
  pos_notes: string | null;
  system_notes: string | null;
}

export interface AddressDto extends Address, Customer {
  id?: string;
  address_name: string;
  is_same_as_shipping_address: boolean;
  is_default: boolean;
  date_of_birth: Date | string | null;
  email: string | null;
  phone_number: string | null;
}

export interface ProfilePayload
  extends Omit<
    GeneralDto,
    'preferred_primary_location' | 'secondary_location'
  > {
  preferred_primary_location_id?: string | null;
  secondary_location_id?: string | null;
}

export type TierData = {
  id: string;
  wine_type: WineType | null;
  bottle_quantity: BottleQuantity | null;
};

export interface ClubMembershipValues {
  tier: TierData;
  add_on: Partial<TierData> | null;
  account_group: AccountGroup | null;
  isPickup?: boolean;
  hasShipment?: boolean;
  freight_company?: FreightCompany;
  freight_method?: FreightMethod;
  on_hold?:
    | (CustomerOnHold & {
        defaultOnHoldSource?: SelectOption;
        freeze_all?: boolean;
        freeze_cycles?: boolean;
        exclude_cycles?: boolean;
      })
    | null;
  pickup: {
    location_id: string;
  } | null;
  shipment: {
    address_id: string | null;
    address?: Omit<AddressDto, 'id'>;
  } | null;
  reason?: ClubMembershipChangeReason | null;
  customer_source_id?: string | null;
}

type ClubMembershipRequest = {
  reason: ClubMembershipChangeReason;
  customer_source: {
    first_name: string;
    id: string;
    last_name: string;
  };
  tier: TireAddonData | null;
  add_on: TireAddonData | null;
};

export type ClubMembershipPayload = Omit<
  ClubMembershipValues,
  'isPickup' | 'tier' | 'hasShipment' | 'account_group'
> & {
  tier: TierData | null;
  club_membership_request?: ClubMembershipRequest | null;
};

export type BillingAddress = {
  first_name: string | null;
  last_name: string | null;
  business: string | null;
  address_line_1: string | null;
  address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  // email: string | null;
};

export type WalletPayload = {
  id?: string;
  token_id: string | null;
  name?: string;
  set_as_default?: boolean;
  billing_address?: BillingAddress;
};

export interface TWalletForm extends Address, Customer {
  card_name: string;
  card_number: string;
  expiration_date: string;
  cvv: string;
  name_on_card: string;
  set_as_default?: boolean;
}

export type CardDto = {
  id: string;
  is_default: boolean;
  card_name: string;
  card_brand: string;
  card_last_4: string;
  stripe_card_id: string;
  billing_address: BillingAddress;
};

export interface CustomerPayload {
  profile: ProfilePayload;
  addresses: AddressDto[];
  wallets: CardDto[];
  club_membership: ClubMembershipPayload;
}

export type TireAddonData = {
  id: string;
  wine_type: WineType;
  bottle_quantity: BottleQuantity;
  club_tier: {
    id: string;
    level: ClubTierLevel;
    name: string;
  };
};

export interface ClubMembershipDto {
  id: string;
  start_date: string | null;
  tier: TireAddonData | null;
  add_on: TireAddonData | null;
  account_group: AccountGroup;
  delivery_method: DeliveryMethod;
  club_membership_request: ClubMembershipRequest | null;
  freight_method?: FreightMethod;
  freight_company?: FreightCompany;
  on_hold: CustomerOnHold | null;
  pickup: {
    location: Location;
  } | null;
  shipment: {
    address: AddressDto;
  } | null;
}

export interface CustomerListDto {
  id: string;
  number: string;
  first_name: string;
  last_name: string;
  wine_club_tier: TireAddonData | null;
  wine_club_add_on: TireAddonData | null;
  wine_club_status: string | null;
  shipment_status: string | null;
  account_group: AccountGroup;
  email: string;
  phone_number: string;
  has_errors: boolean;
  has_all_fields_populated: boolean;
  has_club_membership_request: boolean;
}

export type CustomerError = {
  id: string;
  name: string;
  message: string;
  topic: ErrorTopic;
};

export interface CustomerDto
  extends Omit<CustomerPayload, 'profile' | 'club_membership'> {
  id: string;
  profile: Omit<GeneralDto, 'account_group'> & {
    account_group: AccountGroup;
  };
  club_membership: ClubMembershipDto;
  errors: CustomerError[];
}

type Source = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};

export interface ClubMembershipHistoryDto {
  tier_name: string;
  start_date: string | null;
  end_date: string | null;
  reason_to_start: string | null;
  reason_to_cancel: string | null;
  customer_source_of_start: Source | null;
  customer_source_of_cancel: Source | null;
}

export interface CustomersRequest extends PaginatedRequest {
  search_string?: string;
  only_with_cycle_on_hold?: boolean;
}

export type OrdersHistoryDto = {
  id: string;
  number: number;
  closed_at: Date;
  delivery_method: DeliveryMethod;
  processed_in: OrderSource;
  service_class: ServiceClass;
  source: OrderSource;
  status: OrderStatus;
  supply_type: SupplyType;
  total: number;
  type: OrderType;
};

export interface OrdersHistoryList
  extends Omit<
    OrdersHistoryDto,
    'closed_at' | 'processed_in' | 'status' | 'total'
  > {
  note: string;
  closed_at: string;
  processed_in: string;
  status: string;
  total: string;
}

export type UpcomingShipmentList = {
  id: string;
  product_name: string;
  quantity: number;
  retail_price: string;
  discounted_price: string;
  total_price: string;
};

export type CustomerOnHold = {
  start_date: string | Date | null;
  end_date: string | Date | null;
  reason: ClubMembershipChangeReason;
  notify: boolean;
  source: {
    id: string;
    first_name: string;
    last_name: string;
  };
  type: OnHoldType;
};
