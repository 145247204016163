import { useCallback, useMemo } from 'react';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'shared';

type RequestFilter = { search_string: string };

export const useTiers = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params = useMemo(() => {
    return {
      page,
      search_string,
      page_size: DEFAULT_PAGE_SIZE,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getTiers', key], () => null);

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  const output = useMemo(() => data, [data]);

  return {
    page,
    output,
    search_string,
    isLoading,
    updateFilters,
    handleSetSearchParams,
    totalRecords: 0,
  };
};
