import { useState } from 'react';
import { Container } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {
  CreateCulinarySku,
  culinarySkuSchema,
  getFormAdditionalInformation,
  getFormProfile,
} from 'shared';
import { useInitialForm } from '../../hooks';
import { AdditionalInformation, ProductProfile, SkuWrapper } from 'components';
import { Modifiers } from './formSections';

export type PropsType = {
  handleSubmit: (values: CreateCulinarySku, file: File | null) => void;
  processing: boolean;
  initialValues?: CreateCulinarySku;
  action?: React.ReactNode;
  isCreate?: boolean;
  editMode?: boolean;
  canEdit?: boolean;
};

export const Form = ({
  handleSubmit,
  processing,
  initialValues,
  action,
  isCreate,
  editMode,
  canEdit,
}: PropsType) => {
  const [file, setFile] = useState<File | null>(null);

  const form = useForm({
    initialValues,
    validate: yupResolver(culinarySkuSchema),
    validateInputOnBlur: true,
  });

  const payload = {
    categoryId: form.values.category_id,
    price: form.values.price,
    cost: form.values.cost,
  };

  const { categoryOptions, subcategoryOptions, margin } =
    useInitialForm(payload);

  const isDraft = form.values.saved_as_draft;

  return (
    <Container size={'xl'} p={0}>
      <form
        onSubmit={form.onSubmit((values) => handleSubmit(values, file))}
        noValidate={isDraft}
      >
        <SkuWrapper
          initialValues={initialValues}
          isCreate={isCreate}
          action={action}
          processing={processing}
          setFieldValue={form.setFieldValue}
          isValid={form.isValid()}
          canEdit={canEdit}
          editMode={editMode}
          isDraftDisabled={!form.values.product_name}
        >
          <ProductProfile
            isCulinary
            margin={margin}
            form={getFormProfile(form)}
            skuNumber={initialValues?.sku_number}
            categoryOptions={categoryOptions}
            subcategoryOptions={subcategoryOptions}
          />

          <Modifiers form={form} />

          <AdditionalInformation
            file={file}
            setFile={setFile}
            form={getFormAdditionalInformation(form)}
          />
        </SkuWrapper>
      </form>
    </Container>
  );
};
