import {
  requiredDateValidationRule,
  requiredStringValidationRule,
} from 'shared';
import * as Yup from 'yup';

const getDateFiled = (fieldName: string) => {
  return requiredDateValidationRule(fieldName).when('exclude_cycles', {
    is: true,
    then: () => Yup.date().nullable(),
  });
};

export const freezeMembershipSchema = Yup.object({
  reason: requiredStringValidationRule('Reason'),
  start_date: getDateFiled('Start Date'),
  end_date: getDateFiled('End Date'),
  source: Yup.object({ id: requiredStringValidationRule('Source') }),
  notify: Yup.boolean(),
});
