import { Collapse, Grid, NumberInput, Text, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { DATE_FORMAT_SLASH, PaymentByCheck } from 'shared';

export const PaymentReceived = ({
  isCheck,
  paymentByCheck,
  setPaymentByCheckData,
}: {
  isCheck: boolean;
  paymentByCheck?: PaymentByCheck;
  setPaymentByCheckData: React.Dispatch<
    React.SetStateAction<PaymentByCheck | null>
  >;
}) => {
  return (
    <Collapse in={isCheck} transitionDuration={1000} my="md">
      <Grid>
        <Grid.Col>
          <Text weight={600} size="lg">
            Payment Received
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          {paymentByCheck ? (
            <>
              <Text size="sm" weight={600}>
                Check # / Payment #
              </Text>
              <Text>{paymentByCheck.check_number}</Text>
            </>
          ) : (
            <TextInput
              label="Check # / Payment #"
              data-testid="payment-check-number"
              onChange={({ currentTarget }) => {
                const { value } = currentTarget;

                setPaymentByCheckData((prev) => ({
                  ...prev,
                  check_number: value,
                }));
              }}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          {paymentByCheck ? (
            <>
              <Text size="sm" weight={600}>
                Amount
              </Text>
              <Text>{paymentByCheck.amount}</Text>
            </>
          ) : (
            <NumberInput
              hideControls
              precision={2}
              data-testid="payment-amount"
              label="Amount"
              onChange={(value) => {
                setPaymentByCheckData((prev) => ({
                  ...prev,
                  amount: value,
                }));
              }}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          {paymentByCheck ? (
            <>
              <Text size="sm" weight={600}>
                Date of Payment
              </Text>
              <Text>{paymentByCheck.check_payment_date}</Text>
            </>
          ) : (
            <DatePickerInput
              valueFormat={DATE_FORMAT_SLASH}
              data-testid="payment-date"
              label="Date of Payment"
              placeholder="08.31.2024"
              onChange={(e) => {
                setPaymentByCheckData((prev) => ({
                  ...prev,
                  check_payment_date: e?.toString(),
                }));
              }}
            />
          )}
        </Grid.Col>
      </Grid>
    </Collapse>
  );
};
