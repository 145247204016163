import { Fragment } from 'react';
import { Box } from '@mantine/core';
import {
  PaymentStatus,
  OrderPaymentStatus,
  TransactionType,
  getPaymentMethod,
  getPaymentSummary,
  normalizeLinkOrders,
  normalizePaymentItemsTable,
} from 'shared';
import {
  InventoryDetailsTable,
  LinkOrders,
  PaymentActions,
  PaymentInformation,
} from 'components';
import { Modifiers } from '../modifiers';
import { OrderProps } from './propsType';
import { normalizeRefundItems } from 'pages/orders/posOrders/helpers';

export const GuestAndItemOrder = ({
  orderId,
  orderNumber,
  orderStatus,
  payment,
  isRefunding,
  generationPDFPaymentIds,
  handleRefund,
  handleSendReceipt,
  handleGeneratePartialReceipt,
}: OrderProps) => {
  return (
    <>
      {payment.map((i) => {
        const {
          sales_orders,
          id: paymentId,
          transactions,
          payer,
          payer_name,
          method,
          card_brand,
          card_last4,
          s3_receipt_path,
          signature_url,
          to_refund,
          change,
          status,
          type: paymentType,
        } = i;

        const isRefunded = [
          PaymentStatus.refunded,
          PaymentStatus.partially_refunded,
        ].includes(status);

        const paymentMethod = getPaymentMethod({
          method,
          card_brand,
          card_last4,
        });

        const linkOrders = normalizeLinkOrders(sales_orders, orderId);

        const isOrderRefunded = orderStatus === OrderPaymentStatus.REFUND;

        return (
          <Box key={i.id} sx={{ borderBottom: '1px solid #ADB5BD' }} mb="lg">
            {transactions.map(
              ({
                id,
                detalization,
                succeeded_at,
                created_at,
                status: transactionStatus,
                amount,
                type,
                is_correction,
              }) => {
                const isTransactionRefunded = type === TransactionType.REFUND;

                const orderProducts = detalization.items.map(
                  normalizePaymentItemsTable,
                );
                const summary = getPaymentSummary(detalization).filter(
                  (i) => i[0] !== 'Shipping Fee' && i[0] !== 'Shipping Tax',
                );

                const productItems = normalizeRefundItems(detalization.items);

                return (
                  <Fragment key={id}>
                    <InventoryDetailsTable
                      orderProducts={orderProducts}
                      summary={summary}
                      // ownerNames={owners.join(', ')}
                      isRefunded={isTransactionRefunded}
                      rowExpansion={{
                        trigger: 'always',
                        content: ({ record: { modifiers } }) => (
                          <Modifiers
                            modifiers={modifiers}
                            isRefunded={isRefunded}
                          />
                        ),
                      }}
                    />
                    {!isTransactionRefunded && !is_correction && (
                      <LinkOrders linkOrders={linkOrders} />
                    )}
                    <PaymentInformation
                      status={transactionStatus}
                      succeededAt={succeeded_at}
                      createAt={created_at}
                      payer={payer}
                      payerName={payer_name}
                      method={method}
                      change={change}
                      amount={amount}
                      isRefunded={isTransactionRefunded}
                      paymentMethod={paymentMethod}
                    >
                      <PaymentActions
                        transactionId={id}
                        type={paymentType}
                        productItems={productItems}
                        paymentId={paymentId}
                        paymentMethod={paymentMethod}
                        paymentStatus={status}
                        method={method}
                        payer={payer}
                        toRefund={to_refund}
                        payerName={payer_name}
                        signatureUrl={signature_url}
                        s3ReceiptPath={s3_receipt_path}
                        isRefunded={isTransactionRefunded}
                        isOrderRefunded={isOrderRefunded}
                        isRefunding={isRefunding}
                        isCorrection={is_correction}
                        generationPDFIds={generationPDFPaymentIds}
                        orderNumber={orderNumber}
                        handleRefund={handleRefund}
                        handleSendReceipt={handleSendReceipt}
                        handleRegenerateReceipt={handleGeneratePartialReceipt}
                      />
                    </PaymentInformation>
                  </Fragment>
                );
              },
            )}
          </Box>
        );
      })}
    </>
  );
};
