import { IconInfoCircle } from '@tabler/icons-react';
import { Button, Center, Group, Text, useMantineTheme } from '@mantine/core';
import { CANCEL_BTN } from 'shared';

export enum Steps {
  general_info,
  recipient_upload,
}

type Props = {
  activeStep: number;
  hasFile: boolean;
  hasDownloadFile: boolean;
  isProcessing: boolean;
  isInvalidGeneral: boolean;
  hasShipmentDate: boolean;
  isDeleting?: boolean;
  handleDelete: () => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const UploadFileButtons = ({
  hasFile,
  hasDownloadFile,
  isProcessing,
  isDeleting = false,
  handleDelete,
}: {
  hasFile: boolean;
  hasDownloadFile: boolean;
  isProcessing: boolean;
  isDeleting?: boolean;
  handleDelete: () => void;
}) => {
  const theme = useMantineTheme();
  if (hasDownloadFile)
    return (
      <Button
        fullWidth
        variant="white"
        data-testid={CANCEL_BTN}
        loading={isDeleting}
        onClick={handleDelete}
      >
        Cancel Order Creation
      </Button>
    );

  return (
    <>
      {hasFile ? (
        <Button fullWidth type="submit" loading={isProcessing}>
          Create Orders
        </Button>
      ) : (
        <>
          <IconInfoCircle stroke={1} size={20} color={theme.colors.dark[2]} />
          <Text ml={8} size={12} color={theme.colors.dark[2]}>
            The file should be in CSV format
          </Text>
        </>
      )}
    </>
  );
};

export const CorporateOrderButtons = ({
  activeStep,
  hasFile,
  hasDownloadFile,
  isProcessing,
  isDeleting,
  isInvalidGeneral,
  hasShipmentDate,
  setActiveStep,
  handleDelete,
}: Props) => {
  const disabledNext = isInvalidGeneral || !hasShipmentDate;

  const buttons: Record<string, JSX.Element> = {
    general_info: (
      <Group mt={48}>
        <Button
          fullWidth
          disabled={disabledNext}
          onClick={() => setActiveStep(1)}
        >
          Next
        </Button>
        <Button
          fullWidth
          type="submit"
          disabled={isInvalidGeneral}
          loading={isProcessing}
        >
          Save as Draft
        </Button>
      </Group>
    ),
    recipient_upload: (
      <Center mt={15}>
        <UploadFileButtons
          hasFile={hasFile}
          isDeleting={isDeleting}
          hasDownloadFile={hasDownloadFile}
          isProcessing={isProcessing}
          handleDelete={handleDelete}
        />
      </Center>
    ),
  };

  return buttons[Steps[activeStep]];
};
