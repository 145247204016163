import {
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { CreateSku, SWITCH_GRID_TOP_OFFSET } from 'shared';
import { PROCESSING_TIME } from 'pages/products/skus/constants';

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
};

export const ShippingSettings = ({ form }: PropType) => {
  const fullWidth = { width: '100%' };
  const { values } = form;

  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={12}>
        <Text weight={600} size="lg" mt={'xl'}>
          Shipping Settings
        </Text>
      </Grid.Col>

      <Grid.Col span={6}>
        <Select
          label="Processing Time"
          sx={fullWidth}
          rightSectionWidth={30}
          {...form.getInputProps('processing_time')}
          data={PROCESSING_TIME}
        />
      </Grid.Col>

      <Grid.Col span={3} pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Pickup Only"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('pickup_only', { type: 'checkbox' })}
        />
      </Grid.Col>
      <Grid.Col span={3} pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Members Only"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('members_only', {
            type: 'checkbox',
          })}
        />
      </Grid.Col>

      <Grid.Col span={2}>
        <NumberInput
          hideControls
          sx={fullWidth}
          label="Width (in)"
          {...form.getInputProps('width')}
          value={values.width ?? ''}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <NumberInput
          hideControls
          sx={fullWidth}
          label="Height (in)"
          {...form.getInputProps('height')}
          value={values.height ?? ''}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <NumberInput
          hideControls
          sx={fullWidth}
          label="Depth (in)"
          {...form.getInputProps('depth')}
          value={values.depth ?? ''}
        />
      </Grid.Col>

      <Grid.Col span={6} pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Ships Separately"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('ships_separately', { type: 'checkbox' })}
        />
      </Grid.Col>

      <Grid.Col span={3}>
        <NumberInput
          hideControls
          sx={fullWidth}
          label="Weight (lb)"
          {...form.getInputProps('weight')}
          value={values.weight ?? ''}
        />
      </Grid.Col>

      <Grid.Col span={3}>
        <TextInput
          sx={fullWidth}
          label="Per Order Limit (pc)"
          {...form.getInputProps('per_order_limit')}
        />
      </Grid.Col>
    </Grid>
  );
};
