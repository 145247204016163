import { useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import { Button, Container, LoadingOverlay, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';

import {
  ClubMembershipChangeReason,
  SelectOption,
  ClubMembershipValues,
  ClubMembershipDto,
  OrderPaymentStatus,
  LOADING_OVERLAY,
} from 'shared';
import {
  Reason,
  History,
  SkipHistory,
  TastingLimit,
  TastingHistory,
  FreezeMembership,
} from './components';
import { getClubMemberSchema } from '../../forms/schemes';
import { ClubMemberForm } from '../../forms/ClubMemberForm';
import { ClubMemberStatus } from '../../forms/components';
import { NONE_VALUE } from '../../../constants';
import { ChangeWindow } from './components/changeWindow';
import { useClubMembershipRequest, useTastingLimit } from './hooks';
import { useCustomerOrders } from 'pages/crm/customers/hooks';

enum Mode {
  EDIT = 'edit',
  HISTORY = 'history',
  SKIP_HISTORY = 'skip history',
  TASTING_HISTORY = 'tasting history',
}

type Props = {
  customerId: string;
  isShipment?: boolean;
  clubMembership: ClubMembershipDto;
  canEdit?: boolean;
  addressOptions: SelectOption[];
  isUpdatingClubMembership: boolean;
  initialClubMemberValues?: ClubMembershipValues;
  errors: JSX.Element;
  refetch: () => void;
  submitClubMembership: ({
    value,
    reason,
    update_orders,
    customer_source_id,
  }: {
    value: ClubMembershipValues;
    update_orders?: boolean;
    reason?: ClubMembershipChangeReason | null;
    customer_source_id?: string | null;
  }) => void;
};

export const EditClubMembership = ({
  isShipment = false,
  customerId,
  clubMembership,
  canEdit,
  addressOptions,
  errors,
  isUpdatingClubMembership,
  initialClubMemberValues,
  refetch,
  submitClubMembership,
}: Props) => {
  const [mode, setMode] = useState<Mode>(Mode.EDIT);
  const [updateOrders, setUpdateOrders] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: initialClubMemberValues,
    validate: yupResolver(getClubMemberSchema(true)),
    validateInputOnBlur: true,
  });

  const { tastingLimit, isLoadingTastingLimit, currentDate } = useTastingLimit({
    customerId,
  });

  const { values } = form;
  const { on_hold } = initialClubMemberValues ?? {};

  const titleOnHoldButton = on_hold
    ? 'Freeze Membership Details'
    : 'Freeze Membership';

  const { isRequest, changeTierData, requestAction, isLoading } =
    useClubMembershipRequest({
      customerId,
      clubMembership,
      refetch,
      setInitialValues: form.setValues,
    });

  const {
    orders,
    allOpenOrders,
    isLoading: isLoadingOrders,
    isLoadingAllOpenOrders,
    refetchAllOpenOrders,
  } = useCustomerOrders({
    customerId,
    data: {
      ...(initialClubMemberValues?.pickup?.location_id
        ? { location: { id: initialClubMemberValues?.pickup?.location_id } }
        : {}),
      ...(initialClubMemberValues?.shipment?.address?.zip_code
        ? {
            address: {
              ...initialClubMemberValues?.shipment?.address,
              first_name:
                initialClubMemberValues?.shipment?.address.first_name ?? '',
              last_name:
                initialClubMemberValues?.shipment?.address.last_name ?? '',
            },
          }
        : {}),
    },
    paymentStatus: OrderPaymentStatus.UNPAID,
    enabled: !!clubMembership,
    enabledAllOpenOrders: true,
  });
  const isUnpaid = !!orders?.items.length;
  const hasOpenOrders = !!allOpenOrders?.length;

  const isNoneTier = values.tier.id === NONE_VALUE;

  const hasChanges =
    initialClubMemberValues &&
    (initialClubMemberValues.tier.id !== values.tier.id ||
      initialClubMemberValues.add_on?.id !== values.add_on?.id);

  const isValid = form.isValid();

  const confirmTitle = isNoneTier ? 'Cancel Membership' : 'Add a Comment';
  const hasTier = !isNoneTier && !!initialClubMemberValues?.tier.wine_type;

  if (mode === Mode.HISTORY) {
    return (
      <History customerId={customerId} goBack={() => setMode(Mode.EDIT)} />
    );
  }
  if (mode === Mode.SKIP_HISTORY) {
    return (
      <SkipHistory customerId={customerId} goBack={() => setMode(Mode.EDIT)} />
    );
  }
  if (mode === Mode.TASTING_HISTORY) {
    return (
      <TastingHistory
        customerId={customerId}
        goBack={() => setMode(Mode.EDIT)}
      />
    );
  }

  return (
    <>
      <LoadingOverlay
        data-testid={LOADING_OVERLAY}
        visible={
          isLoadingOrders || isLoadingTastingLimit || isLoadingAllOpenOrders
        }
        overlayBlur={2}
      />
      <Container size="sm">{errors}</Container>
      {isRequest && (
        <ChangeWindow
          isLoading={isLoading}
          changeTierData={changeTierData}
          requestAction={requestAction}
        />
      )}
      <ClubMemberForm
        isEdit
        form={form}
        isShipment={isShipment}
        isUnpaid={isUnpaid}
        updateOrders={updateOrders}
        setUpdateOrders={setUpdateOrders}
        accountGroup={clubMembership.account_group}
        addressOptions={addressOptions}
        tastingLimitSection={
          hasTier && (
            <TastingLimit tastingLimit={tastingLimit} currentDate={currentDate}>
              <Button
                variant="subtle"
                onClick={() => setMode(Mode.TASTING_HISTORY)}
              >
                See Tasting History
              </Button>
            </TastingLimit>
          )
        }
        skipHistory={
          <Button variant="subtle" onClick={() => setMode(Mode.SKIP_HISTORY)}>
            See Skip History
          </Button>
        }
        statusData={
          <ClubMemberStatus
            date={clubMembership.start_date}
            onClick={() => setMode(Mode.HISTORY)}
          />
        }
        actions={
          <>
            <Button
              fullWidth
              loading={isUpdatingClubMembership}
              disabled={!isValid || !canEdit}
              onClick={() => {
                if (hasChanges) {
                  openModal({
                    size: 'md',
                    title: (
                      <Text size="md" weight={600}>
                        {confirmTitle}
                      </Text>
                    ),
                    children: (
                      <Reason
                        formValue={values}
                        isNoneTier={isNoneTier}
                        onSubmit={submitClubMembership}
                      />
                    ),
                  });
                } else {
                  submitClubMembership({
                    value: values,
                    update_orders: updateOrders,
                  });
                }
              }}
            >
              Save
            </Button>
            <Button
              my="md"
              fullWidth
              variant="white"
              data-testid="freeze-membership-btn"
              onClick={open}
            >
              {titleOnHoldButton}
            </Button>
          </>
        }
      />

      {(!isUpdatingClubMembership || isLoadingAllOpenOrders) && (
        <FreezeMembership
          customerId={customerId}
          allOpenOrders={allOpenOrders}
          opened={opened}
          initialOnHold={on_hold}
          hasOpenOrders={hasOpenOrders}
          close={close}
          refetch={refetch}
          refetchAllOpenOrders={refetchAllOpenOrders}
        />
      )}
    </>
  );
};
