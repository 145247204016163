import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const TIME_ZONE_LA = 'America/Los_Angeles';
export const DATE_FORMAT_DOT = 'MM.DD.YYYY';
export const DATE_FORMAT_SLASH = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT_SLASH = 'MM/DD/YYYY hh:mm A';

export const getDateOutput = (date?: string): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(DATE_FORMAT_DOT) ?? '';
};

export const getDateSOutput = (
  date?: string | null,
  format: string = DATE_FORMAT_SLASH,
): string => {
  if (!date) {
    return '';
  }

  return dayjs(date).tz(TIME_ZONE_LA, false).format(format) ?? '';
};

export const convertToCaliforniaTZ = (data: string) =>
  dayjs(data).tz(TIME_ZONE_LA, false);

export const getDate = (date?: string | null) => {
  if (date) return new Date(dayjs(date).format(DATE_FORMAT_SLASH));
  return null;
};
