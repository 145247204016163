import { Grid, Select, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import { isNil } from 'rambda';
import {
  CycleOrderPayload,
  FREIGHT_COMPANY,
  FREIGHT_METHOD,
  DATE_FORMAT_SLASH,
  getCurrencyString,
  FREIGHT_COMPANY_DROPDOWN,
  FREIGHT_METHOD_DROPDOWN,
  SHIPPING_DATE_INPUT,
  TRACKING_NUMBER_INPUT,
} from 'shared';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  shippingCost?: number;
  isBlocked?: boolean;
};

export const FreightData = ({
  form,
  shippingCost,
  isBlocked = false,
}: PropType) => {
  return (
    <Grid gutter="xl">
      <Grid.Col span={4}>
        <Select
          required
          label="Freight Company"
          disabled={isBlocked}
          data={FREIGHT_COMPANY}
          data-testid={FREIGHT_COMPANY_DROPDOWN}
          {...form.getInputProps('freight_company')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          label="Freight Method"
          disabled={isBlocked}
          data={FREIGHT_METHOD}
          data-testid={FREIGHT_METHOD_DROPDOWN}
          {...form.getInputProps('freight_method')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DatePickerInput
          required
          label="Shipping Date"
          placeholder="mm/dd/yyyy"
          disabled={isBlocked}
          valueFormat={DATE_FORMAT_SLASH}
          data-testid={SHIPPING_DATE_INPUT}
          {...form.getInputProps('shipping_date')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          label="Tracking #"
          data-testid={TRACKING_NUMBER_INPUT}
          {...form.getInputProps('tracking_number')}
        />
      </Grid.Col>
      <Grid.Col>
        <Text size="sm" weight={600}>
          Shipping Fee
        </Text>
        <Text mt="xs" data-testid="shipping-fee">
          {isNil(shippingCost) ? 'N/A' : getCurrencyString(shippingCost)}
        </Text>
      </Grid.Col>
    </Grid>
  );
};
