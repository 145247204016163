import { ComponentType, ForwardRefExoticComponent, RefAttributes } from 'react';
import { IconProps, Icon } from '@tabler/icons-react';
import { Permissions } from 'shared';

export interface RouteConfig {
  label: string;
  path: string;
  dataTestId?: string;
  key: RouteName;
  component: ComponentType;
  authenticated: boolean;
  showInMenu?: boolean;
  icon?: ForwardRefExoticComponent<
    Omit<IconProps, 'ref'> & RefAttributes<Icon>
  >;
  children?: RouteName[];
  menuLevel?: number;
  permission?: Permissions;
}

export enum RouteName {
  SignIn = 'SignIn',
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
  Dashboard = 'Dashboard',
  Reports = 'Reports',
  Sales = 'Sales',
  VisitorCount = 'VisitorCount',
  VineClubGoals = 'VineClubGoals',
  SystemSettings = 'SystemSettings',
  Locations = 'Locations',
  Employees = 'Employees',
  Roles = 'Roles',
  ProductManagement = 'ProductManagement',
  ProductSKUs = 'ProductSKUs',
  ProductCategories = 'ProductCategories',
  Inventory = 'Inventory',
  InventoryCatalog = 'InventoryCatalog',
  InventoryLocations = 'InventoryLocations',
  InventoryTransfer = 'InventoryTransfer',
  InventoryAdjustment = 'InventoryAdjustment',
  InventoryAllocation = 'InventoryAllocation',
  Culinary = 'Culinary',
  CulinarySKUs = 'CulinarySKUs',
  CulinaryMenu = 'CulinaryMenu',
  Partners = 'Partners',
  Vendors = 'Vendors',
  TaxesFees = 'TaxesFees',
  Crm = 'Crm',
  Customers = 'Customers',
  MembersClub = 'MembersClub',
  ShipmentCycle = 'ShipmentCycle',
  CycleOrders = 'CycleOrders',
  Discounts = 'Discounts',
  Orders = 'Orders',
  PosOrders = 'PosOrders',
  SalesOrders = 'SalesOrders',
  CorporateOrders = 'CorporateOrders',
  Tiers = 'Tiers',
}

export type Containers =
  | RouteName.Reports
  | RouteName.SystemSettings
  | RouteName.ProductManagement
  | RouteName.Inventory
  | RouteName.Employees
  | RouteName.Culinary
  | RouteName.Crm
  | RouteName.MembersClub
  | RouteName.Discounts
  | RouteName.Orders
  | RouteName.Tiers;
