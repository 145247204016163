import { FC } from 'react';
import { Button, NumberInput, Grid, Text, ActionIcon } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { SkuSearch } from 'components';
import { InventoryFormValues, PRODUCT_INPUT } from 'shared';
import { getNewItem } from './helpers';

export type Props = {
  form: UseFormReturnType<
    InventoryFormValues,
    (values: InventoryFormValues) => InventoryFormValues
  >;
};

export const ProductItems: FC<Props> = ({ form }): JSX.Element => {
  const canRemove = form.values.items.length > 1;
  return (
    <Grid gutter="xl">
      <Grid.Col span={9}>
        <Text weight={500} size="sm">
          Product
        </Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text weight={500} size="sm">
          Qty
        </Text>
      </Grid.Col>
      <Grid.Col span={12}>
        {form.values.items.map(({ key }, i) => {
          return (
            <Grid gutter="xl" key={key}>
              <Grid.Col span={9}>
                <SkuSearch
                  data-testid={PRODUCT_INPUT}
                  {...form.getInputProps(`items.${i}.sku_id`)}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <NumberInput
                  min={1}
                  precision={0}
                  hideControls
                  data-testid="product-quantity-input"
                  {...form.getInputProps(`items.${i}.quantity`)}
                  value={form.values.items[i].quantity ?? ''}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <ActionIcon
                  ml={'auto'}
                  mt="8px"
                  size="sm"
                  disabled={!canRemove}
                  onClick={() => form.removeListItem('items', i)}
                  aria-label="DeleteButton"
                  color="dark.9"
                >
                  <IconTrash stroke={1} />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </Grid.Col>
      <Grid.Col span={12}>
        <Button
          variant="subtle"
          onClick={() => form.insertListItem('items', getNewItem())}
        >
          + Add New Product
        </Button>
      </Grid.Col>
    </Grid>
  );
};
