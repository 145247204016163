import { Grid, Text, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  COMMENT_TEXTAREA,
  CreateDiscount,
  PRODUCT_DESCRIPTION_TEXTAREA,
} from 'shared';

type Props = {
  form: UseFormReturnType<CreateDiscount>;
};

export const AdditionalInformation = ({ form }: Props) => {
  const {
    values: { is_used },
  } = form;

  return (
    <Grid gutter="xl">
      <Grid.Col mt="lg">
        <Text color="dark" weight={600} size="lg">
          Additional Information
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <Textarea
          label="Product Description"
          disabled={is_used}
          data-testid={PRODUCT_DESCRIPTION_TEXTAREA}
          {...form.getInputProps('description')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Textarea
          label="Comment (not shown in POS)"
          disabled={is_used}
          data-testid={COMMENT_TEXTAREA}
          {...form.getInputProps('comment')}
        />
      </Grid.Col>
    </Grid>
  );
};
