import { ActionIcon, Flex, Grid, Select, SelectItem } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { CreateDiscount, LOCATION_DROPDOWN } from 'shared';

type Props = {
  form: UseFormReturnType<CreateDiscount>;
  data: SelectItem[];
  index: number;
  disabledDeleteLocation: boolean;
};

export const PrimaryLocation = ({
  form,
  data,
  index,
  disabledDeleteLocation,
}: Props) => {
  const canRemove = form.values.location_ids.length > 1;

  return (
    <Grid.Col span={12}>
      <Flex gap="lg" align="center" sx={{ width: '50%', paddingRight: '12px' }}>
        <Select
          sx={{ width: '100%' }}
          required
          disabled={disabledDeleteLocation}
          label="Primary Location"
          rightSectionWidth={30}
          data-testid={LOCATION_DROPDOWN}
          data={data}
          {...form.getInputProps(`location_ids.${index}`)}
          onChange={(value) => {
            form.setFieldValue(`location_ids.${index}`, value);

            form.setFieldValue('hide_from_ecommerce', true);
          }}
        />
        <ActionIcon
          mt="lg"
          data-testid="delete-primary-location-btn"
          onClick={() => form.removeListItem('location_ids', index)}
          disabled={!canRemove || disabledDeleteLocation}
        >
          <IconTrash stroke={1} />
        </ActionIcon>
      </Flex>
    </Grid.Col>
  );
};
