import { CorporateOrderPayload } from 'shared';
import { getNewPackage } from '../helper';

export const accept = '.csv';

export const listColumns = [
  {
    accessor: 'number',
    title: 'Order #',
    width: '9%',
  },
  {
    accessor: 'customer',
    title: 'Customer',
    width: '29%',
  },
  {
    accessor: 'last4',
    title: 'Card',
    width: '8%',
  },
  {
    accessor: 'createdAt',
    title: 'Date',
    width: '12%',
  },
  {
    accessor: 'subOrders',
    title: 'Sub-Orders',
    width: '12%',
  },
  {
    accessor: 'total',
    title: 'Total',
    width: '10%',
  },
  {
    accessor: 'status',
    title: 'Order Status',
    width: '20%',
  },
];

export const subOrderColumns = [
  {
    accessor: 'number',
    title: 'Order #',
    width: '10%',
  },
  {
    accessor: 'recipientName',
    title: 'Recipient Name',
    width: '28%',
  },
  {
    accessor: 'membership',
    title: 'Membership',
    width: '32%',
  },
  {
    accessor: 'choice',
    title: 'Choice',
    width: '15%',
  },
  {
    accessor: 'complianceCheck',
    title: 'Compliance Check',
    width: '15%',
  },
];

export const mockInitialValues = {
  freight_company: '',
  freight_method: '',
  customer_id: '',
  customer_card_id: '',
  shipping_date: null,
  gift_packages: [getNewPackage()],
} as unknown as CorporateOrderPayload;
