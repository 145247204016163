import { Checkbox, Grid, NumberInput, Text } from '@mantine/core';
import { RefundedItem, getCurrencyString } from 'shared';

type Props = {
  paymentId: string;
  refundItem: RefundedItem;
  handleCalculateRefund: ({
    paymentId,
    item,
  }: {
    paymentId: string;
    item: {
      item_id?: string;
      item_quantity: number;
    };
  }) => void;
};

export const Item = ({
  paymentId,
  refundItem,
  handleCalculateRefund,
}: Props) => {
  const {
    price,
    quantity,
    refunded_quantity,
    isSelected,
    item_id,
    product_name,
    chosen_quantity,
    membership_discount,
    sub_total,
  } = refundItem;

  const currentQuantity = quantity - refunded_quantity;

  return (
    <Grid grow gutter="lg" mb={12}>
      <Grid.Col span={1}>
        <Checkbox
          checked={isSelected}
          onChange={(e) => {
            if (!e) return;

            if (e.target.checked) {
              return handleCalculateRefund({
                paymentId,
                item: { item_id, item_quantity: 1 },
              });
            } else {
              handleCalculateRefund({
                paymentId,
                item: { item_id, item_quantity: 0 },
              });
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size="sm">{product_name}</Text>
      </Grid.Col>
      <Grid.Col span={2} sx={{ height: '50px' }}>
        {isSelected ? (
          <NumberInput
            size="xs"
            min={1}
            max={currentQuantity}
            value={chosen_quantity ?? ''}
            onChange={(value) => {
              if (!value) return;
              const itemQuantity =
                value > currentQuantity ? currentQuantity : value;

              handleCalculateRefund({
                paymentId,
                item: { item_id, item_quantity: itemQuantity },
              });
            }}
          />
        ) : (
          <Text size="sm" align="end">
            {currentQuantity}
          </Text>
        )}
      </Grid.Col>
      <Grid.Col span={2}>
        <Text size="sm" align="end">
          {getCurrencyString(price)}
        </Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text size="sm" align="end">
          {getCurrencyString(membership_discount)}
        </Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text size="sm" align="end">
          {getCurrencyString(sub_total)}
        </Text>
      </Grid.Col>
    </Grid>
  );
};
