import { FC } from 'react';
import {
  Box,
  Button,
  NumberInput,
  Grid,
  Text,
  ActionIcon,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { FeeValues } from 'shared';
import { getNewFeeItem } from './helpers';

export type Props = {
  form: UseFormReturnType<FeeValues, (values: FeeValues) => FeeValues>;
};

export const FeeItems: FC<Props> = ({ form }): JSX.Element => {
  const canRemove = form.values.fees.length > 0;
  const feeItems = form.values.fees;
  return (
    <Box mb="xl">
      <Grid gutter="lg">
        <Grid.Col span={6}>
          <Text size={'sm'} weight={500}>
            Fee Name
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid gutter={'xl'}>
            <Grid.Col span={9}>
              <Text size={'sm'} weight={500}>
                Fee Value, (%)
              </Text>
            </Grid.Col>
            <Grid.Col span={3}></Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      {feeItems.map(({ id }, i) => {
        return (
          <Grid gutter="lg" key={id}>
            <Grid.Col span={6}>
              <TextInput required {...form.getInputProps(`fees.${i}.name`)} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid gutter={'xl'}>
                <Grid.Col span={9}>
                  <NumberInput
                    min={0}
                    precision={2}
                    hideControls
                    {...form.getInputProps(`fees.${i}.value`)}
                    value={form.values.fees[i].value ?? ''}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <ActionIcon
                    ml={'auto'}
                    mt="8px"
                    size="sm"
                    disabled={!canRemove}
                    onClick={() => form.removeListItem('fees', i)}
                    aria-label="DeleteButton"
                    color="dark.9"
                  >
                    <IconTrash stroke={1} />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        );
      })}
      <Box mt="md">
        <Button
          variant="subtle"
          onClick={() => form.insertListItem('fees', getNewFeeItem())}
        >
          + Add Fee
        </Button>
      </Box>
    </Box>
  );
};
