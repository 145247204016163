import { randomId } from '@mantine/hooks';
import dayjs from 'dayjs';
import { uniq, reject } from 'rambda';
import {
  OrderDto,
  DATE_FORMAT_SLASH,
  PosSalesOrderDto,
  getFullName,
  PosGuest,
  PosSalesItem,
  getCurrencyString,
  PaymentMethod,
  capitalizeText,
  Modifier,
  getDateSOutput,
  PosSalesPaymentType,
  RefundType,
  ItemTransaction,
} from 'shared';

export const normalizePosSalesOrdersList = (data?: PosSalesOrderDto[]) => {
  if (!data) return [];

  return data.map(
    ({ guests, created_at, fully_paid_at, total, tips = 0, ...rest }) => {
      const customers = guests
        .map(({ customer }) =>
          customer
            ? getFullName({
                first_name: customer.first_name,
                last_name: customer.last_name,
              })
            : '',
        )
        .filter((customer) => !!customer)
        .join(', ');

      return {
        ...rest,
        total: total + tips,
        created_at: getDateSOutput(created_at),
        fully_paid_at: getDateSOutput(fully_paid_at),
        customers:
          customers.length >= 45 ? `${customers.slice(0, 45)}...` : customers,
      };
    },
  );
};

export const normalizeRefundItems = (items: ItemTransaction[]) =>
  items.map(({ sales_order_item, quantity }) => ({
    id: sales_order_item.id,
    quantity,
    payment_status: sales_order_item.payment_status,
  }));

export const normalizeModifiers = ({
  modifiers,
}: {
  modifiers?: Modifier[];
}) => {
  const mappingModifiers = modifiers?.map(
    ({ variants, quantity, name, price, ...rest }) => {
      const variant = variants[0]?.name ? `(${variants[0].name})` : '';

      return {
        ...rest,
        quantity: quantity || '-',
        name: `${name} ${variant}`,
        price: getCurrencyString(price),
      };
    },
  );

  return mappingModifiers?.filter(({ is_excluded }) => !is_excluded);
};

export const normalizePosOrder = (order: OrderDto): OrderDto => {
  return {
    ...order,
    created_at: dayjs(order.created_at).format(DATE_FORMAT_SLASH),
  };
};

export const getCustomerInformation = (
  data: PosGuest,
): { id: string; name: string; clubTier: string } => {
  const { customer, name } = data;

  if (customer) {
    return {
      id: randomId(),
      name: `${customer.first_name} ${customer.last_name ?? ''}`.trim(),
      clubTier: customer.main_tier?.club_tier.name ?? 'N/A',
    };
  }
  return { id: randomId(), name: name ?? 'N/A', clubTier: 'N/A' };
};

export const filterGuestHasItems = (guests: PosGuest[]): PosGuest[] =>
  guests.filter((g) => !!g.checkout?.items?.length);

export const getPaymentMethod = ({
  method,
  card_brand = '',
  card_last4 = '',
}: {
  method: PaymentMethod;
  card_brand: string | null;
  card_last4: string | null;
}) => {
  if (method === PaymentMethod.cash) return capitalizeText(method);

  if (method === PaymentMethod.card && card_brand)
    return `${capitalizeText(card_brand)} **** **** **** ${card_last4}`;
};

export const getOwners = (items: PosSalesItem[]) => {
  const owners = items.map(({ owner }) => {
    if (owner) {
      const { first_name, last_name } = owner;
      const name = getFullName({
        first_name,
        last_name,
      });

      return name;
    }
    return 'N/A';
  });

  return uniq(reject((owner) => !owner, owners));
};

export const getRefundPosOrderType = ({
  orderType,
  refundType,
}: {
  orderType?: PosSalesPaymentType;
  refundType: RefundType;
}) => {
  const isTransaction =
    orderType &&
    [
      PosSalesPaymentType.OTHER,
      PosSalesPaymentType.PER_ITEM,
      PosSalesPaymentType.PER_GUEST,
    ].includes(orderType);

  if (isTransaction && refundType !== RefundType.ITEM_BASED)
    return RefundType.TRANSACTION_BASED;

  return refundType;
};
