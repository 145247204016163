import { ReactNode } from 'react';
import {
  Container,
  Box,
  Button,
  Text,
  Group,
  LoadingOverlay,
  Paper,
  TextInput,
  Select,
} from '@mantine/core';
import { isEmpty } from 'rambda';
import { useForm, yupResolver } from '@mantine/form';

import { GoogleAutocomplete, MaskedInput } from 'components';
import {
  locationSchema,
  CreateLocation,
  LOADING_OVERLAY,
  stateOptions,
  STATE_DROPDOWN,
} from 'shared';
import { useAutocompleteAddress } from 'hooks';

export interface Props {
  handleSubmit: (data: CreateLocation) => void;
  processing: boolean;
  title: string;
  initialValues: CreateLocation;
  children?: ReactNode;
}

export const Form = ({
  handleSubmit,
  processing,
  title,
  initialValues,
  children,
}: Props): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(locationSchema),
    validateInputOnBlur: true,
  });

  const { setCurrentData } = useAutocompleteAddress<CreateLocation>({
    setValues: form.setValues,
  });

  return (
    <Paper p="lg">
      <Container size={'xs'} sx={{ position: 'relative' }}>
        <LoadingOverlay
          data-testid={LOADING_OVERLAY}
          visible={processing}
          overlayBlur={2}
        />
        <Text
          data-testid="new-location-title"
          size={'lg'}
          weight={700}
          align="center"
          mt={16}
          mb={24}
        >
          {title}
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Box mb="lg">
            <TextInput
              label="Location Name"
              data-testid="location-name-input"
              required
              {...form.getInputProps('name')}
            />
          </Box>
          <Box mb="lg">
            <TextInput
              label="City"
              data-testid="city-input"
              required
              {...form.getInputProps('city')}
            />
          </Box>
          <Box mb="lg">
            <GoogleAutocomplete setValues={setCurrentData}>
              <TextInput
                label="Address"
                data-testid="address-input"
                required
                {...form.getInputProps('address')}
              />
            </GoogleAutocomplete>
          </Box>
          <Box mb="lg">
            <Group grow align={'flex-start'}>
              <Select
                required
                label="State"
                data-testid={STATE_DROPDOWN}
                data={['', ...stateOptions]}
                {...form.getInputProps('state')}
              />
              <TextInput
                label="Zip Code"
                data-testid="zipcode-input"
                required
                {...form.getInputProps('zip_code')}
              />
            </Group>
          </Box>
          <Box>
            <MaskedInput
              label="Phone Number"
              mask="(999) 999-9999"
              testId="phoneNumber"
              required
              {...form.getInputProps('phone_number')}
            />
          </Box>
          <Box mb="xl" mt={40}>
            <Button
              disabled={!isEmpty(form.errors)}
              data-testid="submit-btn"
              type="submit"
              fullWidth
              loading={processing}
            >
              Save
            </Button>
          </Box>
        </form>
        {children ?? null}
      </Container>
    </Paper>
  );
};
