import request from '../../request';
import {
  CustomerDto,
  CustomerListDto,
  CustomerPayload,
  CustomersRequest,
  ProfilePayload,
  PaginatedResponse,
  ClubTierResponse,
  ClubTierQuery,
  ClubMembershipPayload,
  ClubMembershipHistoryDto,
  AddressDto,
  ClubMembershipDto,
  PaginatedRequest,
  OrdersHistoryDto,
  OrderDto,
  WalletPayload,
  CardDto,
  TastingHistory,
  TastingLimit,
  BillingAddress,
  CustomerOnHold,
} from 'shared';
import { CUSTOMER_PATH } from '../../endpoints';

export default class CustomerService {
  static readonly create = (data: CustomerPayload) => {
    return request<CustomerDto>({
      url: CUSTOMER_PATH,
      method: 'post',
      data,
    });
  };

  static readonly resetPassword = ({ id }: { id?: string }) => {
    return request<CustomerDto>({
      url: `${CUSTOMER_PATH}/${id}/reset-password`,
      method: 'post',
    });
  };

  static readonly getCollection = (params?: CustomersRequest) => {
    return request<PaginatedResponse<CustomerListDto>>({
      url: CUSTOMER_PATH,
      params,
      method: 'get',
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<CustomerDto>({
      url: `${CUSTOMER_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly getClubTiers = (params: ClubTierQuery) => {
    return request<ClubTierResponse>({
      url: 'club-tiers',
      method: 'get',
      params,
    });
  };

  static readonly updateProfile = ({
    id,
    data,
  }: {
    id?: string;
    data: ProfilePayload;
  }) => {
    return request<CustomerDto>({
      url: `${CUSTOMER_PATH}/${id}/profile`,
      method: 'patch',
      data,
    });
  };

  static readonly updateClubMembership = ({
    data,
    customer_id,
    club_membership_id,
    update_orders,
  }: {
    data: ClubMembershipPayload;
    customer_id: string;
    club_membership_id: string;
    update_orders?: boolean;
  }) => {
    return request<ClubMembershipDto>({
      url: `${CUSTOMER_PATH}/${customer_id}/club-membership/${club_membership_id}`,
      method: 'put',
      data,
      ...(update_orders ? { params: { update_orders } } : {}),
    });
  };

  static readonly getClubMembershipHistory = (id: string) => {
    return request<{ items: ClubMembershipHistoryDto[] }>({
      url: `${CUSTOMER_PATH}/${id}/club-membership-history`,
      method: 'get',
    });
  };

  static readonly getCards = ({ id }: { id?: string }) => {
    return request<PaginatedResponse<CardDto>>({
      url: `${CUSTOMER_PATH}/${id}/card`,
      method: 'get',
    });
  };

  static readonly createCard = ({
    id,
    data,
    update_orders,
  }: {
    id?: string;
    data: WalletPayload;
    update_orders?: boolean;
  }) => {
    return request<CardDto>({
      url: `${CUSTOMER_PATH}/${id}/card`,
      method: 'post',
      data,
      ...(update_orders ? { params: { update_orders } } : {}),
    });
  };

  static readonly deleteCard = ({
    id,
    idCard,
  }: {
    id?: string;
    idCard?: string;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/card/${idCard}`,
      method: 'delete',
    });
  };

  static readonly setDefaultCard = ({
    id,
    idCard,
    update_orders,
  }: {
    id?: string;
    idCard?: string;
    update_orders?: boolean;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/card/${idCard}/set-default`,
      method: 'post',
      ...(update_orders ? { params: { update_orders } } : {}),
    });
  };

  static readonly updateBillingAddress = ({
    id,
    idCard,
    data,
  }: {
    id?: string;
    idCard?: string;
    data: BillingAddress;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/card/${idCard}/billing-address`,
      method: 'put',
      data,
    });
  };

  static readonly updateCardName = ({
    id,
    idCard,
    card_name,
  }: {
    id?: string;
    idCard?: string;
    card_name: string;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/card/${idCard}/rename`,
      method: 'post',
      data: {
        card_name,
      },
    });
  };

  static readonly getAddresses = ({ id }: { id?: string }) => {
    return request<PaginatedResponse<AddressDto>>({
      url: `${CUSTOMER_PATH}/${id}/address?page_size=49`,
      method: 'get',
    });
  };

  static readonly addAddress = ({
    id,
    data,
    update_orders,
  }: {
    id?: string;
    data: AddressDto;
    update_orders?: boolean;
  }) => {
    return request<AddressDto>({
      url: `${CUSTOMER_PATH}/${id}/address`,
      method: 'post',
      data,
      ...(update_orders ? { params: { update_orders } } : {}),
    });
  };

  static readonly getOrdersHistory = ({
    id,
    params,
  }: {
    id?: string;
    params?: PaginatedRequest;
  }) => {
    return request<PaginatedResponse<OrdersHistoryDto>>({
      url: `${CUSTOMER_PATH}/${id}/orders/history`,
      method: 'get',
      params,
    });
  };

  static readonly getUpcomingShipment = ({
    id,
    params,
  }: {
    id?: string;
    params?: PaginatedRequest;
  }) => {
    return request<PaginatedResponse<OrderDto>>({
      url: `${CUSTOMER_PATH}/${id}/orders/upcoming`,
      method: 'get',
      params,
    });
  };

  static readonly editAddress = ({
    id,
    data,
    addressId,
    update_orders,
  }: {
    id?: string;
    data: AddressDto;
    addressId?: string;
    update_orders?: boolean;
  }) => {
    return request<AddressDto>({
      url: `${CUSTOMER_PATH}/${id}/address/${addressId}`,
      method: 'patch',
      data,
      ...(update_orders ? { params: { update_orders } } : {}),
    });
  };

  static readonly deleteAddress = ({
    id,
    addressId,
  }: {
    id?: string;
    addressId?: string;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/address/${addressId}`,
      method: 'delete',
    });
  };

  static readonly getTastingHistory = ({
    id,
    params,
  }: {
    id?: string;
    params?: PaginatedRequest;
  }) => {
    return request<PaginatedResponse<TastingHistory>>({
      url: `${CUSTOMER_PATH}/${id}/tasting/history`,
      method: 'get',
      params,
    });
  };

  static readonly getTastingLimits = ({
    id,
    year,
    month,
  }: {
    id?: string;
    year: number;
    month: number;
  }) => {
    return request<TastingLimit>({
      url: `${CUSTOMER_PATH}/${id}/tasting/total-limits`,
      method: 'get',
      params: {
        year,
        month,
      },
    });
  };

  static readonly setCustomerOnHold = ({
    id,
    data,
  }: {
    id?: string;
    data: CustomerOnHold;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/on-hold`,
      method: 'post',
      data,
    });
  };

  static readonly updateCustomerOnHold = ({
    id,
    data,
  }: {
    id?: string;
    data: CustomerOnHold;
  }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/on-hold`,
      method: 'put',
      data,
    });
  };

  static readonly removeCustomerOnHold = ({ id }: { id?: string }) => {
    return request({
      url: `${CUSTOMER_PATH}/${id}/on-hold`,
      method: 'delete',
    });
  };
}
