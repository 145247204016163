import { Container, SelectItem } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { discountSchema } from './schema';
import {
  AdditionalInformation,
  AdditionalSettings,
  ProductProfile,
  Toggles,
} from './formSections';
import { CreateDiscount, DiscountUnit, SAVE_BTN, SelectOption } from 'shared';
import { getNewItem } from './helpers';
import { useInitialForm } from '../../hooks';
import { ActionButtons } from 'components';

export type Props = {
  isEdit?: boolean;
  options: SelectOption[];
  tiersOptions: SelectItem[];
  initialValues?: CreateDiscount;
  handleSubmit: (values: CreateDiscount) => void;
};

export const mockValues: CreateDiscount = {
  name: '',
  is_used: false,
  is_active: false,
  value: 0,
  hide_from_ecommerce: false,
  description: '',
  comment: '',
  start_date: null,
  end_date: null,
  inventory_type: null,
  minimum_quantity: 0,
  minimum_total: 0,
  applied_to_glass: false,
  free_shipping: false,
  one_time_promotion: false,
  club_tier_id: null,
  unit: DiscountUnit.dollar,
  location_ids: [getNewItem()],
  category_id: null,
  subcategory_id: null,
};

export const Form = ({
  isEdit,
  options,
  tiersOptions,
  initialValues,
  handleSubmit,
}: Props) => {
  const form = useForm({
    initialValues: initialValues ?? mockValues,
    validate: yupResolver(discountSchema),
    validateInputOnBlur: true,
  });
  const { values } = form;
  const { categoryOptions, subcategoryOptions } = useInitialForm(
    values.category_id,
  );

  const disabled = !form.isValid() || !form.isDirty();

  return (
    <Container size="xl" my="xl">
      <ProductProfile
        isEdit={isEdit}
        form={form}
        options={options}
        initialLocationIds={initialValues?.location_ids ?? []}
      />
      <AdditionalSettings
        form={form}
        categoryOptions={categoryOptions}
        subcategoryOptions={subcategoryOptions}
      />
      <Toggles form={form} tiersOptions={tiersOptions} />
      <AdditionalInformation form={form} />

      <ActionButtons
        label="Save"
        my={48}
        disabled={disabled}
        data-testid={SAVE_BTN}
        onClick={() => handleSubmit(form.values)}
      />
    </Container>
  );
};
