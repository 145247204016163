import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { Fragment } from 'react';
import {
  AdjustmentType,
  adjustmentTypeOptions,
  getAdjustmentItem,
  InventoryTransferDto,
  InventoryTransferStatus,
  RECEIVE_BTN,
} from 'shared';
import { TransferView } from '../TransferView';
import { receiveSchema } from './schema';

type Props = {
  handleSubmit: ({
    status,
    value,
  }: {
    status: InventoryTransferStatus;
    value: InventoryTransferDto;
  }) => void;
  initialValues?: InventoryTransferDto;
};

export const FormReceive = ({
  handleSubmit,
  initialValues,
}: Props): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(receiveSchema),
  });
  const [opened, { open, close }] = useDisclosure(false);

  const flex = {
    display: 'flex',
    justifyContent: 'flex-start',
  };
  const fullWidth = { width: '100%' };
  const gap = 50;

  const {
    origin_inventory_location: origin,
    destination_inventory_location: destination,
    items,
  } = initialValues ?? {};

  const { values } = form;
  const isAdjust = values.items.some(
    (i) => (i.quantity ?? 0) - i.received_quantity !== i.to_receive_quantity,
  );

  return (
    <>
      <Container size="sm">
        <form>
          <TransferView
            originLocationName={origin?.location_name}
            originInventoryLocationName={origin?.name}
            destinationLocationName={destination?.location_name}
            destinationInventoryLocationName={destination?.name}
          />

          {items?.map((sku, index: number) => {
            const remained = (sku.quantity ?? 0) - (sku.received_quantity ?? 0);

            return (
              <Flex
                key={sku.sku_id}
                sx={fullWidth}
                justify="space-between"
                align="center"
                gap={gap}
                mb="xs"
              >
                <Box sx={fullWidth}>
                  <Text size={14}>{sku.sku_product_name}</Text>
                </Box>
                <Flex align="center" justify="space-between" sx={fullWidth}>
                  <Flex align="center" gap={10} sx={fullWidth}>
                    <NumberInput
                      data-testid="quantity-to-receive-input"
                      styles={{ input: { width: 75 } }}
                      hideControls
                      min={1}
                      max={
                        sku?.quantity && sku?.quantity - sku.received_quantity
                      }
                      {...form.getInputProps(
                        `items.${index}.to_receive_quantity`,
                      )}
                      value={form.values.items[index].to_receive_quantity ?? ''}
                    />

                    <Box>/</Box>
                    <Text
                      data-testid="quantity-to-receive-text"
                      sx={flex}
                      size={14}
                    >
                      {remained}
                    </Text>
                  </Flex>
                  <Flex sx={{ ...fullWidth, justifyContent: 'center' }}>
                    <Text size={14}>{sku.received_quantity}</Text>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
          <Container
            data-testid="receive-partially-container"
            my="xl"
            size="xs"
          >
            <Button
              data-testid="receive-partially-btn"
              mb="lg"
              fullWidth
              onClick={() =>
                handleSubmit({
                  status: InventoryTransferStatus.partially_received,
                  value: values,
                })
              }
            >
              Receive Partially
            </Button>
            <Button
              data-testid={RECEIVE_BTN}
              onClick={() => {
                if (isAdjust) {
                  open();
                  form.setFieldValue(
                    'receiveItems',
                    values.items.map((sku) => getAdjustmentItem(sku)),
                  );
                } else {
                  handleSubmit({
                    status: InventoryTransferStatus.fully_received,
                    value: values,
                  });
                }
              }}
              variant="outline"
              fullWidth
            >
              Receive
            </Button>
          </Container>
        </form>
      </Container>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text data-testid="add-correction-notes-text" weight={600}>
            Add Correction Notes
          </Text>
        }
      >
        <Select
          required
          label="Adjustment Type"
          sx={{ ...fullWidth, marginBottom: '24px' }}
          rightSectionWidth={30}
          {...form.getInputProps('adjustment_type')}
          data={adjustmentTypeOptions}
          data-testid="adjustment-type-dropdown"
          onChange={(value) => {
            form.setFieldValue('adjustment_type', value as AdjustmentType);
          }}
        />
        <Grid gutter="xl">
          <Grid.Col span={10}>
            <Text weight={600}>Product Name or SKU#</Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <Text weight={600} align="end">
              Qty
            </Text>
          </Grid.Col>
          {values?.receiveItems?.map((item) => (
            <Fragment key={item.name}>
              <Grid.Col span={10}>
                <Text>{item?.name}</Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text align="end">{item.quantityAdjust}</Text>
              </Grid.Col>
            </Fragment>
          ))}
        </Grid>
        <Textarea mt={30} label="Comment" {...form.getInputProps('comment')} />
        <Button
          data-testid="adjust-btn"
          mt={40}
          variant="filled"
          disabled={!form.isValid()}
          onClick={() => {
            handleSubmit({
              status: InventoryTransferStatus.fully_received,
              value: values,
            });
            close();
          }}
          fullWidth
        >
          Adjust
        </Button>
      </Modal>
    </>
  );
};
