import { Button, Grid, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import {
  BottleQuantity,
  WineType,
  WineSelectionDto,
  WineSelection,
  ADD_PRODUCT_BTN,
} from 'shared';
import { AddProduct } from './AddProduct';
import { getNewSkuItem } from '../../../../helpers';

type Props = {
  type: WineType;
  form: UseFormReturnType<WineSelectionDto>;
  bottleQuantityAllowed?: BottleQuantity[];
  isEdit?: boolean;
  paste: ({
    currentItems,
  }: {
    currentItems: WineSelection[];
  }) => { key: string; sku_id?: string }[];
  setCopy: (payload: WineSelection[]) => void;
  copy: WineSelection[] | null;
};

export const WineSelectionBottles = ({
  type,
  form,
  copy,
  isEdit,
  paste,
  setCopy,
  bottleQuantityAllowed,
}: Props) => {
  return (
    <Grid gutter="xl">
      {bottleQuantityAllowed?.map((bottleSize) => (
        <Grid.Col key={bottleSize} span={6}>
          <Text size={18} weight={600} mb={24}>
            {bottleSize} Bottles
          </Text>
          <AddProduct
            form={form}
            type={type}
            copy={copy}
            isEdit={isEdit}
            paste={paste}
            setCopy={setCopy}
            bottleSize={bottleSize}
          >
            <Button
              variant="white"
              p="0"
              data-testid={ADD_PRODUCT_BTN}
              onClick={() =>
                form.insertListItem(
                  'wine_selections',
                  getNewSkuItem({ type, bottleSize }),
                )
              }
            >
              + Add Product
            </Button>
          </AddProduct>
        </Grid.Col>
      ))}
    </Grid>
  );
};
