import { Flex, Grid, NumberInput, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { MaskedInput } from 'components';
import {
  CreateDiscount,
  INVENTORY_TYPE_DROPDOWN,
  SelectOption,
  inventoryTypeOptions,
} from 'shared';

type Props = {
  categoryOptions: SelectOption[];
  subcategoryOptions: SelectOption[];
  form: UseFormReturnType<CreateDiscount>;
};

export const AdditionalSettings = ({
  form,
  categoryOptions,
  subcategoryOptions,
}: Props) => {
  const { values } = form;
  const { is_used } = values;
  const fullWidth = { width: '100%' };

  return (
    <Grid gutter="xl">
      <Grid.Col>
        <Text color="dark" weight={600} size="lg">
          Additional Settings
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <MaskedInput
          testId="startDate"
          mask="99.99.9999"
          label="Start Date"
          placeholder="08.31.1989"
          sx={fullWidth}
          maskChar={null}
          disabled={is_used}
          {...form.getInputProps('start_date')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <MaskedInput
          testId="endDate"
          sx={fullWidth}
          mask="99.99.9999"
          label="End Date"
          placeholder="08.31.1989"
          maskChar={null}
          {...form.getInputProps('end_date')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Select
          clearable
          label="Inventory Type"
          disabled={is_used}
          rightSectionWidth={30}
          data-testid={INVENTORY_TYPE_DROPDOWN}
          sx={{ width: '50%', paddingRight: 12 }}
          {...form.getInputProps('inventory_type')}
          data={inventoryTypeOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          clearable
          label="Applied to Category"
          sx={fullWidth}
          disabled={is_used}
          rightSectionWidth={30}
          data-testid="applied-to-category-dropdown"
          {...form.getInputProps('category_id')}
          onChange={(value) => {
            form.setFieldValue('subcategory_id', null);
            form.setFieldValue('category_id', value);
          }}
          data={categoryOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          clearable
          label="Applied for Sub-Category"
          data-testid="applied-for-sub-category-dropdown"
          sx={fullWidth}
          rightSectionWidth={30}
          disabled={!subcategoryOptions.length || is_used}
          {...form.getInputProps('subcategory_id')}
          data={subcategoryOptions}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Flex gap="xl">
          <NumberInput
            hideControls
            label="Min Qty (Pc)"
            data-testid="min-qty-pc-input"
            min={0}
            disabled={is_used}
            sx={fullWidth}
            {...form.getInputProps('minimum_quantity')}
            value={values.minimum_quantity ?? 0}
          />
          <NumberInput
            hideControls
            label="Min Total ($)"
            data-testid="min-total-input"
            sx={fullWidth}
            precision={2}
            disabled={is_used}
            parser={(value) => value?.replace(/\$\s?|%\s?/g, '')}
            formatter={(value) => (value ? `$${value}` : '')}
            {...form.getInputProps('minimum_total')}
            value={values.minimum_total ?? 0}
          />
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
