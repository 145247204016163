import { Grid, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  CycleOrderPayload,
  OrderDto,
  OrderPaymentStatus,
  supplyTypeLabels,
  TRACKING_NUMBER_INPUT,
} from 'shared';

export const DeliveryInformation = ({
  order,
  form,
}: {
  order: OrderDto;
  form?: UseFormReturnType<
    CycleOrderPayload,
    (values: CycleOrderPayload) => CycleOrderPayload
  >;
}) => {
  const {
    delivery_method,
    deplete_location,
    shipping_address,
    supply_type,
    deplete_inventory_location,
    pick_up_deadline,
    tracking_number,
    freight_company,
    freight_method,
    shipping_date,
    payment_status,
  } = order;

  const isPickup = delivery_method === 'Pickup';
  const isPartialRefund =
    payment_status === OrderPaymentStatus.PARTIALLY_REFUND;

  const trackingNumber = isPartialRefund ? (
    <Grid.Col span={4}>
      <TextInput
        label="Tracking #"
        data-testid={TRACKING_NUMBER_INPUT}
        {...form?.getInputProps('tracking_number')}
      />
    </Grid.Col>
  ) : (
    <Grid.Col span={4}>
      <Text weight={600} mb="sm">
        Tracking #
      </Text>
      <Text>{tracking_number ?? '-'}</Text>
    </Grid.Col>
  );

  return (
    <Grid gutter="xl">
      <Grid.Col>
        <Text size="lg" weight={600}>
          Delivery Information
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Delivery Method
        </Text>
        <Text>{delivery_method}</Text>
      </Grid.Col>
      {isPickup ? (
        <Grid.Col span={4}>
          <Text weight={600} mb="sm">
            Pickup Location
          </Text>
          <Text>{deplete_location?.name}</Text>
        </Grid.Col>
      ) : (
        <Grid.Col span={4}>
          <Text weight={600} mb="sm">
            Shipping Address
          </Text>
          <Text>{shipping_address?.address_line_1}</Text>
        </Grid.Col>
      )}
      <Grid.Col span={4} />
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Supply Type
        </Text>
        <Text>{supplyTypeLabels[supply_type]}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Deplete from Primary Location
        </Text>
        <Text>{deplete_location?.name}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Deplete from Inventory Location
        </Text>
        <Text>{deplete_inventory_location?.name ?? '-'}</Text>
      </Grid.Col>
      {isPickup ? (
        <Grid.Col span={4}>
          <Text weight={600} mb="sm">
            Pickup Deadline
          </Text>
          <Text>{pick_up_deadline}</Text>
        </Grid.Col>
      ) : (
        <>
          <Grid.Col span={4}>
            <Text weight={600} mb="sm">
              Freight Company
            </Text>
            <Text>{freight_company}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={600} mb="sm">
              Freight Method
            </Text>
            <Text>{freight_method}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={600} mb="sm">
              Shipping Date
            </Text>
            <Text>{shipping_date}</Text>
          </Grid.Col>
          {trackingNumber}
        </>
      )}
    </Grid>
  );
};
