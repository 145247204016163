import { Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { ImageUpload } from 'components';
import { CreateSku, SkuFormImages } from 'shared';

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
  images: SkuFormImages;
  setImages: React.Dispatch<React.SetStateAction<SkuFormImages>>;
};

export const Images = ({ form, images, setImages }: PropType) => {
  const { web_hover_image_url, web_image_url, mobile_image_url } = form.values;
  return (
    <>
      <Grid.Col span={4}>
        <ImageUpload
          file={images.webImage}
          title="Web Image (Main)"
          fileUrl={web_image_url ?? undefined}
          onFileChange={(f: File) =>
            setImages((prev) => ({ ...prev, webImage: f }))
          }
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <ImageUpload
          file={images.webHoverImage}
          title="Web Image (Hover)"
          fileUrl={web_hover_image_url ?? undefined}
          onFileChange={(f: File) =>
            setImages((prev) => ({ ...prev, webHoverImage: f }))
          }
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <ImageUpload
          file={images.mobileImage}
          title="Mobile Image"
          fileUrl={mobile_image_url ?? undefined}
          onFileChange={(f: File) =>
            setImages((prev) => ({ ...prev, mobileImage: f }))
          }
        />
      </Grid.Col>
    </>
  );
};
