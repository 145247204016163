import { omit } from 'rambda';
import { ProductSkuService } from 'App/api';
import { useApiMutation, useFileUpload } from 'hooks';
import {
  CreateSku,
  getInventoryType,
  getSkuType,
  getSpecificInformation,
  SkuFormImages,
} from 'shared';
import { getCategoryOptions } from './helpers';

type Props = {
  onSuccess: (id: string) => void;
};

export const useCreateSkus = ({ onSuccess }: Props) => {
  const {
    mutateAsync,
    isLoading: processingUpload,
    handleMassUpload,
  } = useFileUpload();
  const { mutate: createSku, isLoading } = useApiMutation(
    ['createSku'],
    ProductSkuService.create,
    {
      onSuccess: (data) => onSuccess(data.id),
    },
  );

  const getImageUrls = async (
    images: SkuFormImages | undefined,
  ): Promise<Partial<CreateSku>> => {
    if (!images) {
      return {};
    }
    const urls = await handleMassUpload([
      images.webImage,
      images.webHoverImage,
      images.mobileImage,
    ] as File[]);
    return {
      web_image_url: urls[0].url,
      web_hover_image_url: urls[1].url,
      mobile_image_url: urls[2].url,
    };
  };

  const handleSubmit = async (
    values: CreateSku,
    file: File | null,
    images?: SkuFormImages,
  ) => {
    const imageUrls = await getImageUrls(images);
    const valueCreate = omit(['sku_number'], values);
    const type = valueCreate?.inventory_type;
    const isKit = valueCreate?.is_kit ?? false;
    const isNonInventory = valueCreate?.is_non_inventory ?? false;
    const isBtg = (valueCreate.specific_information?.is_available_by_glass ??
      values.specific_information?.is_available_only_by_glass) as boolean;

    const payload = {
      ...getInventoryType(type, isBtg, isKit, valueCreate),
      sku_type: getSkuType(type, isKit),
      per_order_limit: values.per_order_limit ?? null,
      price: values.price ?? null,
      vendor_id: values.vendor_id === 'null' ? null : values.vendor_id,
      show_in_ecommerce: values.show_in_ecommerce,
      specific_information: getSpecificInformation({
        type,
        isKit,
        isNonInventory,
        value: valueCreate.specific_information,
      }),
      category_options: getCategoryOptions(values.category_options),
      ...imageUrls,
    };

    if (!file) return createSku(payload);

    await mutateAsync(file, {
      onSuccess: ({ url }) => {
        createSku({ ...payload, product_file: url || null });
      },
    });
  };

  return {
    handleSubmit,
    isLoading,
    processingUpload,
  };
};
