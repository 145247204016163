import { generatePath, useNavigate, Params } from 'react-router-dom';
import {
  IconHome,
  IconChartPie,
  IconSettings,
  IconId,
  IconBox,
  IconClipboardCheck,
  IconToolsKitchen2,
  IconListNumbers,
  IconCrown,
  IconPercentage,
  IconHistory,
  IconLayoutGrid,
} from '@tabler/icons-react';

import { RouteConfig, RouteName, Containers } from './interfaces';
import { Environment, Permissions } from 'shared';

import {
  Dashboard,
  SignIn,
  ForgotPassword,
  ResetPassword,
  Locations,
  Roles,
  Employees,
  Categories as ProductCategories,
  Catalog as InventoryCatalog,
  InventoryLocations,
  Skus,
  Vendors,
  Transfer as InventoryTransfer,
  Partners,
  Allocation,
  Taxes,
  CulinarySkus,
  Menu,
  Customers,
  ShipmentCycle,
  CycleOrders,
  Discounts,
  PosOrders,
  SalesOrders,
  CorporateOrders,
  Tiers,
} from 'pages';
import { Adjustment as InventoryAdjustment } from 'pages/inventory/adjustment';

const { REACT_APP_ENVIRONMENT } = process.env;
const isProduction = REACT_APP_ENVIRONMENT === Environment.Production;

const EmptyComponent = () => null;

export const containerPaths: { [key in Containers]: string } = {
  [RouteName.Reports]: '/reports',
  [RouteName.SystemSettings]: '/system-settings',
  [RouteName.ProductManagement]: '/product-management',
  [RouteName.Inventory]: '/inventory',
  [RouteName.Employees]: '/employees',
  [RouteName.Culinary]: '/culinary',
  [RouteName.MembersClub]: '/members-club',
  [RouteName.Crm]: '/crm',
  [RouteName.Discounts]: '/discounts',
  [RouteName.Orders]: '/orders',
  [RouteName.Tiers]: '/tiers',
};

const routes: Record<RouteName, RouteConfig> = {
  [RouteName.SignIn]: {
    label: 'Sign In',
    key: RouteName.SignIn,
    path: '/signin',
    component: SignIn,
    authenticated: false,
  },
  [RouteName.ForgotPassword]: {
    label: 'Forgot Password',
    key: RouteName.ForgotPassword,
    path: '/forgot-password',
    component: ForgotPassword,
    authenticated: false,
  },
  [RouteName.ResetPassword]: {
    label: 'Reset Password',
    key: RouteName.ResetPassword,
    path: '/reset-password/:token',
    component: ResetPassword,
    authenticated: false,
  },
  [RouteName.Dashboard]: {
    label: 'Admin Dashboard',
    path: '/dashboard',
    key: RouteName.Dashboard,
    component: Dashboard,
    authenticated: true,
    showInMenu: false,
    menuLevel: 0,
    icon: IconHome,
  },
  [RouteName.Reports]: {
    label: 'Reporting Dashboard',
    path: containerPaths[RouteName.Reports],
    key: RouteName.Reports,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: false,
    children: [
      RouteName.Sales,
      RouteName.VisitorCount,
      RouteName.VineClubGoals,
    ],
    menuLevel: 0,
    icon: IconChartPie,
  },
  [RouteName.Sales]: {
    label: 'Sales',
    path: `${containerPaths[RouteName.Reports]}/sales`,
    key: RouteName.Sales,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
  },
  [RouteName.VisitorCount]: {
    label: 'Visitor Count',
    path: `${containerPaths[RouteName.Reports]}/visitor-count`,
    key: RouteName.VisitorCount,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
  },
  [RouteName.VineClubGoals]: {
    label: 'Vine Club Goals',
    path: `${containerPaths[RouteName.Reports]}/vine-club-goals`,
    key: RouteName.VineClubGoals,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
  },
  [RouteName.Employees]: {
    label: 'Employee Management',
    path: `${containerPaths[RouteName.Employees]}/*`,
    key: RouteName.Employees,
    component: Employees,
    authenticated: true,
    showInMenu: true,
    children: [],
    menuLevel: 0,
    icon: IconId,
    permission: Permissions.admin_panel_employees_view,
  },
  [RouteName.Crm]: {
    label: 'CRM',
    path: containerPaths[RouteName.Crm],
    key: RouteName.Crm,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [RouteName.Customers],
    menuLevel: 0,
    icon: IconListNumbers,
  },
  [RouteName.Customers]: {
    label: 'Customers',
    path: `${containerPaths[RouteName.Crm]}/customers/*`,
    key: RouteName.Customers,
    component: Customers,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_customer_view,
  },
  [RouteName.ProductManagement]: {
    label: 'Product Management',
    path: containerPaths[RouteName.ProductManagement],
    key: RouteName.ProductManagement,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [
      RouteName.ProductSKUs,
      RouteName.Vendors,
      RouteName.ProductCategories,
    ],
    menuLevel: 0,
    icon: IconBox,
  },
  [RouteName.ProductSKUs]: {
    label: 'Products',
    path: `${containerPaths[RouteName.ProductManagement]}/sku/*`,
    key: RouteName.ProductSKUs,
    component: Skus,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_sku_view,
  },
  [RouteName.ProductCategories]: {
    label: 'Categories',
    path: `${containerPaths[RouteName.ProductManagement]}/categories/*`,
    key: RouteName.ProductCategories,
    component: ProductCategories,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_categories_view,
  },
  [RouteName.Vendors]: {
    label: 'Vendors',
    path: `${containerPaths[RouteName.ProductManagement]}/vendor/*`,
    key: RouteName.Vendors,
    component: Vendors,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_vendor_view,
  },
  [RouteName.Inventory]: {
    label: 'Inventory',
    path: containerPaths[RouteName.Inventory],
    key: RouteName.Inventory,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [
      RouteName.InventoryCatalog,
      RouteName.InventoryLocations,
      RouteName.InventoryTransfer,
      RouteName.InventoryAdjustment,
      RouteName.InventoryAllocation,
      RouteName.Partners,
    ],
    menuLevel: 0,
    icon: IconClipboardCheck,
  },
  [RouteName.InventoryCatalog]: {
    label: 'Inventory List',
    path: `${containerPaths[RouteName.Inventory]}/catalog/*`,
    key: RouteName.InventoryCatalog,
    component: InventoryCatalog,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_inventory_items_view,
  },
  [RouteName.Partners]: {
    label: 'Partners',
    path: `${containerPaths[RouteName.Inventory]}/partners/*`,
    key: RouteName.Partners,
    component: Partners,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_partner_view,
  },
  [RouteName.InventoryLocations]: {
    label: 'Inventory Locations',
    path: `${containerPaths[RouteName.Inventory]}/locations/*`,
    key: RouteName.InventoryLocations,
    component: InventoryLocations,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_inventory_locations_view,
  },
  [RouteName.InventoryTransfer]: {
    label: 'Inventory Transfer',
    path: `${containerPaths[RouteName.Inventory]}/transfer/*`,
    key: RouteName.InventoryTransfer,
    component: InventoryTransfer,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_inventory_transfer_view,
  },
  [RouteName.InventoryAllocation]: {
    label: 'Inventory Allocation',
    path: `${containerPaths[RouteName.Inventory]}/allocation/*`,
    key: RouteName.InventoryAllocation,
    component: Allocation,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_library_view,
  },
  [RouteName.InventoryAdjustment]: {
    label: 'Inventory Adjustment',
    path: `${containerPaths[RouteName.Inventory]}/adjustment/*`,
    key: RouteName.InventoryAdjustment,
    component: InventoryAdjustment,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_adjustments_view,
  },
  [RouteName.Culinary]: {
    label: 'Culinary',
    path: containerPaths[RouteName.Culinary],
    key: RouteName.Culinary,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [RouteName.CulinarySKUs, RouteName.CulinaryMenu],
    menuLevel: 0,
    icon: IconToolsKitchen2,
  },
  [RouteName.Tiers]: {
    label: 'Tiers',
    path: `${containerPaths[RouteName.Tiers]}/*`,
    key: RouteName.Tiers,
    component: Tiers,
    authenticated: !isProduction,
    showInMenu: true,
    menuLevel: 0,
    icon: IconLayoutGrid,
  },
  [RouteName.CulinarySKUs]: {
    label: 'SKUs',
    path: `${containerPaths[RouteName.Culinary]}/sku/*`,
    key: RouteName.CulinarySKUs,
    component: CulinarySkus,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_culinary_sku_view,
  },
  [RouteName.CulinaryMenu]: {
    label: 'Menu Management',
    path: `${containerPaths[RouteName.Culinary]}/menu/*`,
    key: RouteName.CulinaryMenu,
    component: Menu,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_culinary_menu_view,
  },
  [RouteName.MembersClub]: {
    label: "Member's Club",
    path: containerPaths[RouteName.MembersClub],
    key: RouteName.MembersClub,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [RouteName.ShipmentCycle, RouteName.CycleOrders],
    menuLevel: 0,
    icon: IconCrown,
    permission: Permissions.admin_panel_shipment_cycle_view,
  },
  [RouteName.ShipmentCycle]: {
    label: 'Shipment Cycle',
    path: `${containerPaths[RouteName.MembersClub]}/shipment-cycle/*`,
    key: RouteName.ShipmentCycle,
    component: ShipmentCycle,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_shipment_cycle_view,
  },
  [RouteName.CycleOrders]: {
    label: 'Cycle Orders',
    path: `${containerPaths[RouteName.MembersClub]}/cycle-orders/*`,
    key: RouteName.CycleOrders,
    component: CycleOrders,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_shipment_cycle_sales_order_view,
  },
  [RouteName.Discounts]: {
    label: 'Discounts',
    path: `${containerPaths[RouteName.Discounts]}/*`,
    key: RouteName.Discounts,
    component: Discounts,
    authenticated: true,
    showInMenu: true,
    menuLevel: 0,
    icon: IconPercentage,
    permission: Permissions.admin_panel_discounts_view,
  },
  [RouteName.Orders]: {
    label: 'Orders',
    path: containerPaths[RouteName.Orders],
    key: RouteName.Orders,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [
      RouteName.SalesOrders,
      RouteName.PosOrders,
      RouteName.CorporateOrders,
    ],
    menuLevel: 0,
    icon: IconHistory,
  },
  [RouteName.SalesOrders]: {
    label: 'Sales Orders',
    path: `${containerPaths[RouteName.Orders]}/sales-orders/*`,
    key: RouteName.SalesOrders,
    component: SalesOrders,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_shipment_sales_order_view,
  },
  [RouteName.PosOrders]: {
    label: 'POS Orders',
    path: `${containerPaths[RouteName.Orders]}/pos-orders/*`,
    key: RouteName.PosOrders,
    component: PosOrders,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.pos_sales_order_view,
  },
  [RouteName.CorporateOrders]: {
    label: 'Corporate Orders',
    path: `${containerPaths[RouteName.Orders]}/corporate-orders/*`,
    key: RouteName.CorporateOrders,
    component: CorporateOrders,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_corporate_gift_sales_order_view,
  },
  [RouteName.SystemSettings]: {
    label: 'System Settings',
    path: containerPaths[RouteName.SystemSettings],
    key: RouteName.SystemSettings,
    component: EmptyComponent,
    authenticated: true,
    showInMenu: true,
    children: [RouteName.Locations, RouteName.Roles, RouteName.TaxesFees],
    menuLevel: 0,
    icon: IconSettings,
  },
  [RouteName.Locations]: {
    label: 'Location Settings',
    path: `${containerPaths[RouteName.SystemSettings]}/locations/*`,
    key: RouteName.Locations,
    component: Locations,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_locations_view,
  },
  [RouteName.Roles]: {
    label: 'Access Settings',
    path: `${containerPaths[RouteName.SystemSettings]}/roles/*`,
    key: RouteName.Roles,
    component: Roles,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_roles_view,
  },
  [RouteName.TaxesFees]: {
    label: 'Local Taxes and Fees',
    path: `${containerPaths[RouteName.SystemSettings]}/taxes-fees/*`,
    key: RouteName.TaxesFees,
    component: Taxes,
    authenticated: true,
    showInMenu: true,
    permission: Permissions.admin_panel_local_taxes_view,
  },
};

export const authenticatedRoutes: RouteConfig[] = [];
export const unauthenticatedRoutes: RouteConfig[] = [];
Object.values(routes).forEach((route) => {
  const dest = route.authenticated
    ? authenticatedRoutes
    : unauthenticatedRoutes;
  dest.push(route);
});

export function useGotoRoute(
  name: RouteName,
): (params?: Params<string>) => void {
  const navigate = useNavigate();
  const route = routes[name];
  return function gotoRoute(params) {
    const path = generatePath(route.path, params);
    navigate(path);
  };
}

export function generateRoutePath({
  name,
  params,
}: {
  name: RouteName;
  params?: Params<string>;
}): string {
  const route = routes[name];
  return generatePath(route.path, params);
}
