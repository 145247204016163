import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs, Text } from '@mantine/core';
import { ContentLayout, BackButton, TitleWithBadge } from 'components';
import { useEditCustomer } from '../../hooks';
import { WalletTab } from './walletTab';
import { ProfileTab } from './profileTab';
import { EditClubMembership } from './clubMembershipTab';
import { AddressesTab } from './addressesTab';
import { EditOrders } from './ordersTab';
import { AddressDto, ShipmentCycleTabValue, getTabEditPath } from 'shared';
import { ShipmentCycleTabTitle } from '../../constants';
import { getStatusClubMember } from '../../helpers';
import { ErrorsNotification } from '../common';

type PropType = {
  rootPath: string;
  canEdit: boolean;
};

export const Edit = ({ rootPath, canEdit }: PropType): JSX.Element => {
  const navigate = useNavigate();
  const { id = '', tab = '' } = useParams();
  const {
    data,
    customerErrors,
    addressOptions,
    initialProfileValues,
    initialClubMemberValues,
    isLoading,
    isSendingPassword,
    isUpdatingProfile,
    isUpdatingClubMembership,
    refetch,
    submitProfile,
    resetPassword,
    submitClubMembership,
    goToCreateOrderProps,
  } = useEditCustomer({ id });

  const { club_membership: clubMembership, profile, addresses } = data ?? {};
  const { on_hold } = clubMembership ?? {};
  const { first_name, last_name } = profile ?? {};
  const { profileErrors, walletErrors, addressErrors, clubMemberErrors } =
    customerErrors ?? {};

  const hasProfileError = !!profileErrors?.length;
  const hasAddressesError = !!addressErrors?.length;
  const hasWalletError = !!walletErrors?.length;
  const hasClubMemberError = !!clubMemberErrors?.length;

  const isShipment = !!initialClubMemberValues?.shipment?.address_id;

  const defaultAddress = addresses?.find((address) => address.is_default);
  const defaultAddressWithCusName = {
    ...defaultAddress,
    first_name: first_name ?? '',
    last_name: last_name ?? '',
  } as AddressDto;

  const goBack = () => navigate(-1);
  const getColorTabText = (hasError: boolean) => (hasError ? 'red' : 'black');

  const badgeColor = on_hold ? 'halterBlue.7' : 'red.4';

  const title = (
    <TitleWithBadge
      title={`${first_name} ${last_name}`}
      status={getStatusClubMember({ clubMembership })}
      color={badgeColor}
      variant="filled"
    />
  );

  return (
    <ContentLayout
      isLoading={isLoading}
      processing={isLoading}
      title={title}
      leftAction={<BackButton onClick={goBack} />}
      rightAction={
        <Button component={Link} variant="outline" {...goToCreateOrderProps()}>
          + New Order
        </Button>
      }
    >
      <Tabs
        keepMounted={false}
        value={tab}
        onTabChange={(value) => {
          navigate(getTabEditPath(rootPath, id, value ?? ''));
        }}
      >
        <Tabs.List position="center">
          <Tabs.Tab
            px={12}
            data-testid="customer-tab-profile"
            value={ShipmentCycleTabValue.profile}
          >
            <Text size="md" color={getColorTabText(hasProfileError)}>
              {ShipmentCycleTabTitle.profile}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab
            px={12}
            data-testid="customer-tab-clubMember"
            value={ShipmentCycleTabValue.clubMember}
          >
            <Text size="md" color={getColorTabText(hasClubMemberError)}>
              {ShipmentCycleTabTitle['club-member']}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab
            px={12}
            data-testid="customer-tab-address"
            value={ShipmentCycleTabValue.address}
          >
            <Text size="md" color={getColorTabText(hasAddressesError)}>
              {ShipmentCycleTabTitle.address}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab
            px={12}
            data-testid="customer-tab-wallet"
            value={ShipmentCycleTabValue.wallet}
          >
            <Text size="md" color={getColorTabText(hasWalletError)}>
              {ShipmentCycleTabTitle.wallet}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab
            px={12}
            data-testid="customer-tab-orders"
            value={ShipmentCycleTabValue.orders}
          >
            <Text size="md">{ShipmentCycleTabTitle.orders}</Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={ShipmentCycleTabValue.profile} pt={32}>
          <ProfileTab
            canEdit={canEdit}
            processing={isUpdatingProfile}
            initialValues={initialProfileValues}
            isSendingPassword={isSendingPassword}
            handleSubmit={submitProfile}
            handleResetPassword={() => resetPassword({ id })}
            errors={<ErrorsNotification errors={profileErrors} />}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ShipmentCycleTabValue.wallet} pt={32}>
          <WalletTab
            customerId={id}
            defaultAddress={defaultAddressWithCusName}
            canEdit={canEdit}
            refetch={refetch}
            errors={<ErrorsNotification errors={walletErrors} />}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ShipmentCycleTabValue.address} pt={32}>
          <AddressesTab
            customerId={id}
            profile={profile}
            canEdit={canEdit}
            refetch={refetch}
            errors={<ErrorsNotification errors={addressErrors} />}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ShipmentCycleTabValue.clubMember} pt={32}>
          {data && (
            <EditClubMembership
              refetch={refetch}
              isShipment={isShipment}
              customerId={id}
              canEdit={canEdit}
              addressOptions={addressOptions}
              clubMembership={data.club_membership}
              isUpdatingClubMembership={isUpdatingClubMembership}
              submitClubMembership={submitClubMembership}
              initialClubMemberValues={initialClubMemberValues}
              errors={<ErrorsNotification errors={clubMemberErrors} />}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value={ShipmentCycleTabValue.orders} pt={32}>
          <EditOrders customerId={id} />
        </Tabs.Panel>
      </Tabs>
    </ContentLayout>
  );
};
