import { Fragment } from 'react';
import { Grid, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import {
  PosSalesOrderPayload,
  PosSalesOrderDto,
  capitalizeText,
  getDateSOutput,
  HALTER_RANCH_LOCATION_ID,
  salesOrderChannelLabels,
  SelectOption,
} from 'shared';
import { useEmployees } from 'hooks';
import { getCustomerInformation } from 'pages/orders/posOrders/helpers';
import { SelectAsync } from 'components';

type PropType = {
  form: UseFormReturnType<PosSalesOrderPayload>;
  order: PosSalesOrderDto;
  defaultWineAdvisor: {
    value: string | undefined;
    label: string;
  };
};

export const GeneralInfo = ({ form, order, defaultWineAdvisor }: PropType) => {
  const {
    employeesOptions,
    isLoading,
    hasMore,
    setPageSelectAsync,
    setSearchStringSelectAsync,
  } = useEmployees({
    locationId: HALTER_RANCH_LOCATION_ID,
    page_size: 50,
    noCache: true,
    isSelectAsync: true,
  });

  const { number, created_at, status, payment_status, guests, type, channel } =
    order;

  const guestInformation = guests.map(getCustomerInformation);

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          General Information
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Grid gutter="xl">
          <Grid.Col span={3}>
            <Text weight={600} size={14} mb={'sm'}>
              Order #
            </Text>
            <Text size={14}>{number}</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text weight={600} size={14} mb={'sm'}>
              Creation Date
            </Text>
            <Text size={14}>{getDateSOutput(created_at)}</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text weight={600} size={14} mb={'sm'}>
              Status
            </Text>
            <Text size={14}>{capitalizeText(status.replace('_', ' '))}</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text weight={600} size={14} mb={'sm'}>
              Payment Status
            </Text>
            <Text size={14}>
              {capitalizeText(payment_status.replace('_', ' '))}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col>
        <Grid gutter="xl">
          <Grid.Col span={3}>
            <SelectAsync
              required
              isSearchable
              searchByString={({ search_string }) =>
                setSearchStringSelectAsync(search_string)
              }
              label="Wine Advisor"
              setPage={setPageSelectAsync}
              hasMore={hasMore}
              isLoading={isLoading}
              options={employeesOptions}
              {...form.getInputProps('default_wine_advisor_id')}
              defaultValue={defaultWineAdvisor as SelectOption}
              onChange={(item) => {
                const { value } = item as SelectOption;

                if (value) form.setFieldValue('default_wine_advisor_id', value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Text weight={600} size={14} mb={'sm'}>
              Processed In
            </Text>
            <Text size={14}>{capitalizeText(type.replace('_', ' '))}</Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={6}>
        <Grid gutter="xl">
          <Grid.Col span={6}>
            <Text weight={600} size={14}>
              Customer Name
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text weight={600} size={14}>
              Club Membership
            </Text>
          </Grid.Col>
          {guestInformation.map((guest) => (
            <Fragment key={guest.id}>
              <Grid.Col span={6} py={0}>
                <Text size={14}>{guest.name}</Text>
              </Grid.Col>
              <Grid.Col span={6} py={0}>
                <Text size={14}>{guest.clubTier}</Text>
              </Grid.Col>
            </Fragment>
          ))}
        </Grid>
      </Grid.Col>
      <Grid.Col>
        <Text weight={600} mb="sm">
          Channel
        </Text>
        <Text>{salesOrderChannelLabels[channel]}</Text>
      </Grid.Col>
    </Grid>
  );
};
