import { Button, Container, Grid, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { AddressFields } from './AddressFields';
import { EditBillingAddress, FIRST_NAME_INPUT, LAST_NAME_INPUT } from 'shared';

export const EditBillingAddressForm = ({
  form,
  isLoading,
  handleSubmit,
}: {
  form: UseFormReturnType<EditBillingAddress>;
  isLoading?: boolean;
  handleSubmit: (values: EditBillingAddress) => void;
}) => {
  return (
    <Container size="sm">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col>
            <Text size={18} weight={700}>
              Billing Address
            </Text>
            <TextInput
              required
              label="Card Name"
              {...form.getInputProps('cardName')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              data-testid={FIRST_NAME_INPUT}
              label="First Name"
              {...form.getInputProps('first_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              data-testid={LAST_NAME_INPUT}
              label="Last Name"
              {...form.getInputProps('last_name')}
            />
          </Grid.Col>

          <AddressFields<EditBillingAddress> form={form} />
        </Grid>

        <Container size="xs" my="xl">
          <Button
            fullWidth
            type="submit"
            aria-label="save"
            loading={isLoading}
            disabled={!form.isValid()}
          >
            Save
          </Button>
        </Container>
      </form>
    </Container>
  );
};
