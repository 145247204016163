import { DataTable } from 'mantine-datatable';
import {
  ContentLayout,
  CreateButton,
  SearchInput,
  SearchInputSingleLayout,
} from 'components';
import { PROPS_DATA_TABLE, getCreatePath } from 'shared';
import { useTiers } from '../../hooks';

type Props = {
  canCreate: boolean;
  rootPath: string;
};

export const List = ({ canCreate, rootPath }: Props) => {
  const { page, totalRecords } = useTiers();

  const title = 'Wine Club Tier';

  return (
    <ContentLayout
      title={title}
      processing={false}
      rightAction={
        canCreate && (
          <CreateButton to={getCreatePath(rootPath)}>+ New Tier</CreateButton>
        )
      }
    >
      <SearchInputSingleLayout>
        <SearchInput label="Search" value="" handleSubmit={() => null} />
      </SearchInputSingleLayout>

      <DataTable
        {...PROPS_DATA_TABLE}
        records={[]}
        page={page}
        totalRecords={totalRecords}
        onPageChange={() => null}
        columns={[
          { accessor: '', title: 'Name', width: '18%' },
          { accessor: '', title: 'Locations', width: '28%' },
          { accessor: '', title: 'Active Members', width: '18%' },
          { accessor: '', title: 'Canceled Members', width: '18%' },
          { accessor: '', title: 'Status', width: '18%' },
        ]}
      />
    </ContentLayout>
  );
};
