import { Container, Grid, Select, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { SelectAsync } from 'components';
import {
  AsyncSelect,
  CorporateOrderPayload,
  DATE_FORMAT_SLASH,
  FREIGHT_COMPANY,
  FREIGHT_COMPANY_DROPDOWN,
  FREIGHT_METHOD,
  FREIGHT_METHOD_DROPDOWN,
  PaymentMethod,
  SelectOption,
} from 'shared';
import { WineSelection } from './wineSelection';

type Props = {
  form: UseFormReturnType<
    CorporateOrderPayload,
    (values: CorporateOrderPayload) => CorporateOrderPayload
  >;
  defaultCustomer?: SelectOption;
  asyncSelectUsers: AsyncSelect;
  userCards: SelectOption[] | null;
  isLoadingUsers: boolean;
  getCustomerDataByCustomerId: ({ value, label }: SelectOption) => Promise<{
    defaultCardId: string | undefined;
  }>;
};

export const GeneralInfoForm = ({
  form,
  asyncSelectUsers,
  defaultCustomer,
  userCards,
  isLoadingUsers,
  getCustomerDataByCustomerId,
}: Props) => {
  const { options, hasMore, setSearchStringSelectAsync, setPageSelectAsync } =
    asyncSelectUsers;

  return (
    <Container size="xl">
      <Grid gutter="xl">
        <Grid.Col span={6}>
          <SelectAsync
            required
            isSearchable
            label="Customer"
            options={options}
            isLoading={isLoadingUsers}
            hasMore={hasMore}
            searchByString={({ search_string }) =>
              setSearchStringSelectAsync(search_string)
            }
            setPage={setPageSelectAsync}
            {...form.getInputProps('customer_id')}
            defaultValue={defaultCustomer}
            onChange={async (item) => {
              const { value, label } = item as SelectOption;
              if (value) {
                form.setFieldValue('customer_id', value);
                const { defaultCardId } = await getCustomerDataByCustomerId({
                  value,
                  label,
                });

                form.setFieldValue('customer_card_id', defaultCardId ?? '');
              }
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePickerInput
            required
            placeholder="mm/dd/yyyy"
            label="Estimated Shipment Date"
            valueFormat={DATE_FORMAT_SLASH}
            {...form.getInputProps('shipping_date')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            required
            label="Freight Company"
            data={FREIGHT_COMPANY}
            data-testid={FREIGHT_COMPANY_DROPDOWN}
            {...form.getInputProps('freight_company')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            required
            label="Freight Method"
            data={FREIGHT_METHOD}
            data-testid={FREIGHT_METHOD_DROPDOWN}
            {...form.getInputProps('freight_method')}
          />
        </Grid.Col>
      </Grid>
      <WineSelection form={form} />
      <Grid>
        <Grid.Col span={6}>
          <Text weight={600} size="lg" mb="sm">
            Payment Information
          </Text>
          <Select
            required
            label="Payment Method"
            data={userCards ?? []}
            {...form.getInputProps('customer_card_id')}
            onChange={(value) => {
              if (value === PaymentMethod.check) {
                form.setFieldValue('payment_method', PaymentMethod.check);
              } else {
                form.setFieldValue('payment_method', PaymentMethod.card);
              }
              form.setFieldValue('customer_card_id', value);
            }}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
};
