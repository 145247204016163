import { FC, useState, useCallback } from 'react';
import { TextInput, Switch, Select, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useToast } from 'hooks';
import {
  defaultSelectOptions,
  ProductCategoryFormValues,
  ProductCategoryFormImages,
} from 'shared';
import { ActionButtons, ImageUpload } from 'components';
import { categoryFormSchema } from './validation';
import { AdditionalFields } from './components';
import { useCategories } from '../../../hooks';

export type Props = {
  handleSubmit: (
    values: ProductCategoryFormValues,
    files: ProductCategoryFormImages,
  ) => void;
  initialValues: ProductCategoryFormValues;
  id?: string;
  action?: React.ReactNode;
};

export const Form: FC<Props> = ({
  handleSubmit,
  initialValues,
  id,
  action,
}): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(categoryFormSchema),
    validateInputOnBlur: true,
  });

  const addToast = useToast();

  const [files, setFiles] = useState<ProductCategoryFormImages>({
    webImage: null,
    mobileImage: null,
  });

  const { categoryOptions } = useCategories();

  const options = categoryOptions.filter(({ value }) => value !== id);

  const isSubCategories = !!initialValues.subcategories?.length;

  const mainCategoryValue = form.getInputProps('is_main_category').value;

  const submit = useCallback(
    (values: ProductCategoryFormValues) => {
      if (!files.webImage && !values.web_image_url) {
        addToast.error('Web Image Required!');
        return;
      }
      if (!files.mobileImage && !values.mobile_image_url) {
        addToast.error('Mobile Image Required!');
        return;
      }
      handleSubmit(values, files);
    },
    [addToast, files, handleSubmit],
  );

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Grid gutter={'xl'}>
        <Grid.Col span={12}>
          <TextInput
            required
            label="Category Name"
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Switch
            label="Enable"
            labelPosition="left"
            {...form.getInputProps('active', { type: 'checkbox' })}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Switch
            disabled={isSubCategories}
            label="Main Category"
            labelPosition="left"
            {...form.getInputProps('is_main_category', { type: 'checkbox' })}
            onChange={({ target: { checked: v } }) => {
              form.setFieldValue('is_main_category', v);
              if (v) {
                form.setFieldValue('parent_id', '');
              }
            }}
          />
        </Grid.Col>
        {!mainCategoryValue && (
          <Grid.Col span={12}>
            <Select
              required
              disabled={!options?.length}
              label="Parent Category"
              data={[defaultSelectOptions, ...(options || [])]}
              {...form.getInputProps('parent_id')}
            />
          </Grid.Col>
        )}
        <Grid.Col>
          <Switch
            label="Hide from POS"
            labelPosition="left"
            {...form.getInputProps('hide_from_pos', { type: 'checkbox' })}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Switch
            label="Member’s Only"
            labelPosition="left"
            {...form.getInputProps('members_only', { type: 'checkbox' })}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Switch
            label="Pickup Only"
            labelPosition="left"
            {...form.getInputProps('pickup_only', { type: 'checkbox' })}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Switch
            label="Paid Delivery Only"
            labelPosition="left"
            {...form.getInputProps('paid_delivery_only', { type: 'checkbox' })}
          />
        </Grid.Col>

        <Grid.Col>
          <AdditionalFields form={form} />
        </Grid.Col>

        <Grid.Col>
          <Grid gutter={'xl'}>
            <Grid.Col span={6}>
              <ImageUpload
                title={'Image for Web'}
                file={files.webImage}
                onFileChange={(f) => setFiles((p) => ({ ...p, webImage: f }))}
                fileUrl={form.values.web_image_url}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ImageUpload
                title={'Image for Mobile'}
                file={files.mobileImage}
                onFileChange={(f) =>
                  setFiles((p) => ({ ...p, mobileImage: f }))
                }
                fileUrl={form.values.mobile_image_url}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12} mt={'lg'}>
          <ActionButtons
            label="Save"
            disabled={!form.isValid()}
            secondary={action}
            aria-label="save"
          />
        </Grid.Col>
      </Grid>
    </form>
  );
};
