import { trim } from 'rambda';

import dayjs from 'dayjs';
import { convertToCaliforniaTZ } from './dates';

export function getFullName<
  T extends { first_name?: string; last_name?: string },
>({ first_name = '', last_name = '' }: T): string {
  return trim(`${first_name} ${last_name}`);
}

export const getFormatDate = (date?: string | null) =>
  date
    ? new Date(convertToCaliforniaTZ(date).format('MM/DD/YYYY hh:mm a'))
    : null;

export const formatDate = (
  date?: string | Date | null,
  format = 'YYYY-MM-DD',
) => {
  if (!date) return null;
  return dayjs(date).format(format);
};

export const dateInputOutputFormat = (
  date: string | null,
  type: 'input' | 'output',
) => {
  if (!date) return null;

  const dateParts = date.split(/[-.]/);

  if (type === 'input') {
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${month}-${day}-${year}`;
  }

  if (type === 'output') {
    const month = dateParts[0];
    const day = dateParts[1];
    const year = dateParts[2];

    return `${year}-${month}-${day}`;
  }

  return null;
};

export const capitalizeText = (value: string): string => {
  if (!value) {
    return value;
  }
  return `${value[0].toUpperCase()}${value.slice(1)}`;
};
