import { Grid, Text } from '@mantine/core';
import { getFullName } from 'shared';

type Props = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  email: string;
  phone_number: string;
};

export const RecipientInformation = ({
  recipientInformation,
}: {
  recipientInformation: Props | null;
}) => {
  const { first_name, last_name, date_of_birth, email, phone_number } =
    recipientInformation ?? {};

  const fullRecipientName = getFullName({ first_name, last_name });

  return (
    <>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Recipient’s Name
        </Text>
        <Text>{fullRecipientName}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Recipient’s Date of Birth
        </Text>
        <Text>{date_of_birth}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Recipient’s Email
        </Text>
        <Text>{email}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text weight={600} mb="sm">
          Recipient’s Phone Number
        </Text>
        <Text>{phone_number}</Text>
      </Grid.Col>
    </>
  );
};
