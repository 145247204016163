import { useLocation } from 'react-router-dom';
import { IconTrash } from '@tabler/icons-react';
import { ActionIcon, Grid, NumberInput, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { SelectAsync } from 'components/selectAsync';
import { useSkus } from 'hooks';
import {
  CycleOrderPayload,
  InventoryDetail,
  SelectOption,
  SkuStatus,
  setProductValue,
} from 'shared';
import { SALES_ORDERS } from 'App/api/endpoints';

type Props = {
  form: UseFormReturnType<CycleOrderPayload>;
  index: number;
  id: string | undefined;
  isBlocked: boolean;
  newItemId?: string;
  price: number;
  discount: number;
};

export const Product = ({
  form,
  index,
  id,
  isBlocked,
  newItemId,
  price,
  discount,
}: Props) => {
  const { pathname } = useLocation();
  const isSalesOrder = pathname.split('/')[2] === SALES_ORDERS;

  const { skus, skuOptions, isLoading, setPage, updateFilters, hasMore } =
    useSkus({
      inputParams: {
        status: SkuStatus.Enabled,
        has_inventory: true,
        ...(isSalesOrder ? {} : { is_alcohol: true }),
      },
    });

  const items = form.values.items;
  const item = items[index];
  const canRemove = items?.length > 1;

  const addedItem = !id
    ? skus?.find((sku) => sku.id === item.sku_id)
    : undefined;

  return (
    <Grid gutter="xl" columns={24} mb="xs" align="center">
      <Grid.Col span={7}>
        {!!id || isBlocked ? (
          item.sku_name
        ) : (
          <SelectAsync
            required
            isSearchable
            disabled={!!id || isBlocked}
            searchByString={updateFilters}
            setPage={setPage}
            hasMore={hasMore}
            isLoading={isLoading}
            options={skuOptions}
            {...form.getInputProps(`items.${index}.sku_id`)}
            onChange={(e) => {
              const { value, label } = e as SelectOption;

              if (!value)
                return form.setFieldValue(`items.${index}.sku_id`, null);

              const item = skus?.find((sku) => sku.id === value);
              const setSkuId = setProductValue<InventoryDetail>({
                arr: form.values.items,
                key: newItemId,
                value: {
                  sku_id: value,
                  sku_name: label,
                  price: item?.price ?? 0,
                  sku_inventory_type: item?.inventory_type ?? '',
                },
              });

              form.setFieldValue('items', setSkuId);
            }}
          />
        )}
      </Grid.Col>
      <Grid.Col span={2} offset={3}>
        <NumberInput
          hideControls
          disabled={isBlocked}
          min={1}
          {...form.getInputProps(`items.${index}.quantity`)}
          value={item.quantity ?? ''}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Text align="end">${(addedItem?.price ?? price).toFixed(2)}</Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text align="end">${discount.toFixed(2)}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text align="end">
          ${(addedItem?.price ?? price - discount).toFixed(2)}
        </Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text align="end">
          $
          {(
            (addedItem?.price ?? price - discount) * item.quantity || 0
          ).toFixed(2)}
        </Text>
      </Grid.Col>
      <Grid.Col span={1}>
        <Grid align="center" justify="flex-end">
          <ActionIcon
            data-testid="delete-button"
            disabled={!canRemove || isBlocked}
            onClick={() => form.removeListItem('items', index)}
          >
            <IconTrash size={24} stroke={1} />
          </ActionIcon>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
