import { Button, Grid, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  CorrectionOrder,
  CycleOrderPayload,
  getCheckoutSummary,
  getNewInventoryDetailItem,
  getRecalculationSummary,
  OrderStatus,
  OrderPaymentStatus,
  ADD_PRODUCT_BTN,
} from 'shared';
import { AddProduct } from './AddProduct';
import { SummaryOrder } from '../SummaryOrder';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  isBlocked?: boolean;
  corrections?: CorrectionOrder[];
};

export const InventoryDetails = ({
  form,
  isBlocked = false,
  corrections,
}: PropType) => {
  const { checkout, status, payment_status } = form.values;
  const summary = checkout && getCheckoutSummary(checkout);
  const correctionsSummary =
    corrections && getRecalculationSummary(corrections);

  const isThreeDSConfirmation =
    OrderPaymentStatus.THREE_D_SECURE_CONFIRMATION === payment_status;

  const isProcessingOrder = OrderStatus.PROCESSING === status;

  const disableByOrderStatus = isThreeDSConfirmation || isProcessingOrder;

  return (
    <Grid mb="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Inventory Details
        </Text>
      </Grid.Col>
      <Grid.Col>
        <AddProduct form={form} isBlocked={isBlocked || disableByOrderStatus}>
          <Button
            variant="white"
            p="0"
            data-testid={ADD_PRODUCT_BTN}
            disabled={isBlocked || disableByOrderStatus}
            onClick={() =>
              form.insertListItem('items', getNewInventoryDetailItem())
            }
          >
            + Add Product
          </Button>
        </AddProduct>
      </Grid.Col>
      <Grid.Col>
        {summary?.map((item) => <SummaryOrder key={item[0]} item={item} />)}
      </Grid.Col>
      {correctionsSummary?.map(({ id, summary }) => (
        <Grid.Col key={id}>
          {summary?.map((item) => <SummaryOrder key={item[0]} item={item} />)}
        </Grid.Col>
      ))}
    </Grid>
  );
};
