import { SelectOption } from './common';
import { AccountGroup, TireAddonData } from './customer';
import { ShipmentCycleShortItem } from './membersClub';
import { SkuDto } from './products';

export enum PaymentMethod {
  cash = 'cash',
  card = 'card',
  check = 'check',
}

export enum OrderPaymentStatus {
  PAID = 'paid',
  PROCESSING_PAYMENT = 'processing_payment',
  PARTIALLY_PAID = 'partially_paid',
  UNPAID = 'unpaid',
  VOID = 'void',
  CANCEL = 'cancel',
  ERROR = 'error',
  REFUND = 'refund',
  PARTIALLY_REFUND = 'partially_refund',
  THREE_D_SECURE_CONFIRMATION = 'three_d_secure_confirmation',
}

export enum OrderStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PROCESSING = 'processing',
  WAITING_SHIPPING = 'waiting_shipping',
  WAITING_PICKUP = 'waiting_pickup',
  SHIPPING = 'shipping',
  CLOSED = 'closed',
  PENDING_SHIPPING = 'pending_shipping',
  PENDING_BILLING = 'pending_billing',
  CANCEL = 'cancel',
  SKIPPED = 'skipped',
  PENDING_DELIVERY_METHOD_CHANGE = 'pending_delivery_method_change',
  SUBORDERS_CREATION_IS_SCHEDULED = 'suborders_creation_is_scheduled',
  SUBORDERS_CREATION_IN_PROGRESS = 'suborders_creation_in_progress',
  SUBORDERS_CREATION_ERROR = 'suborders_creation_error',
  RECALCULATION_REQUIRED = 'recalculation_required',
  RECALCULATION_IS_SCHEDULED = 'recalculation_is_scheduled',
  RECALCULATION_IN_PROGRESS = 'recalculation_in_progress',
  RECALCULATION_ERROR = 'recalculation_error',
}

export enum OrderSource {
  ALICE = 'alice',
  POS = 'pos',
  ECOMMERCE = 'ecommerce',
}

export enum OrderChannel {
  EXPERIENCES = 'experiences',
  WINE_CLUB = 'wine_club',
  REGULAR_SALES = 'regular_sales',
  CORPORATE = 'corporate',
}

export interface WineAdvisor {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_system: boolean;
}

interface Customer {
  id: string;
  number: string;
  first_name: string;
  last_name: string;
  main_tier: TireAddonData;
  add_on_tier: TireAddonData | null;
  date_of_birth: string;
  email: string;
  phone_number: string;
  has_errors: boolean;
  account_group: AccountGroup;
}

export interface FeeData {
  id: string;
  name: string;
  amount: number;
}

export interface Checkout {
  id: string;
  customer_id?: string;
  total: number;
  items_price: number;
  taxes: number;
  fees: number;
  fee_data: FeeData[];
  discounts: number;
  tips: number;
  is_paid: boolean;
  shipping?: number;
  shipping_taxes: number;
  shipping_cost: number;
  promocode_discounts: number;
}

export enum SupplyType {
  FULFILLMENT = 'fulfillment',
  PICKUP = 'pickup',
  IN_HOUSE = 'in_house',
}

export enum DeliveryMethod {
  SHIPPING = 'Shipping',
  PICKUP = 'Pickup',
}

export type FreightCompany = 'ups' | 'fedex';
export type FreightMethod = 'air' | 'ground';

export type Card = {
  last4: string;
  brand: string;
  stripe_card_id: string;
};

export type OrderGeneralInformation = {
  number: number;
  status: OrderStatus;
  channel: OrderChannel;
  processed_in: OrderSource | null;
  payment_status: OrderPaymentStatus;
  updated_by: WineAdvisor;
  created_at: string;
  customer: Customer;
};

export type GeneralInfoProps = {
  status: OrderStatus;
  number?: number;
  channel: OrderChannel;
  payment_status: OrderPaymentStatus;
  defaultWineAdvisor: SelectOption;
  shipmentCycle?: ShipmentCycleShortItem;
  createdAt?: string | null;
  customer: Order['customer'];
  processedIn: OrderSource | null;
  updated_by_id?: string;
  shipment_cycle_id?: string;
};

export type OrderItem = {
  id?: string;
  sku_id: string;
  sku?: SkuDto;
  quantity: number;
};

export type Order = {
  id: string;
  number: number;
  status: OrderStatus;
  payment_status: OrderPaymentStatus;
  source: OrderSource;
  processed_in: OrderSource | null;
  channel: OrderChannel;
  updated_by: WineAdvisor;
  customer: Customer;
  checkouts: Checkout[];
  checkout: Checkout;
  items: OrderItem[];
  supply_type: SupplyType;
  created_at: string;
  updated_at: string;
  closed_at: string;
  fully_paid_at: string;
  delivery_method: DeliveryMethod;
  freight_company: FreightCompany;
  freight_method: FreightMethod;
  shipping_cost: number | null;
  shipping_taxes: number | null;
  shipping_date: string | null;
  card: Card;
  billing_datetime: string;
};
