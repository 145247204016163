import { ShipmentSalesOrdersService } from 'App/api';
import { useApiQuery } from 'hooks';
import {
  CustomerCycleOrder,
  CustomerOrderBody,
  getEditPath,
  OrderPaymentStatus,
  OrderStatus,
  OrderType,
} from 'shared';

export const getOrderLink = ({
  id,
  type,
  shipment_cycle,
}: {
  id: string;
  type: CustomerCycleOrder['type'];
  shipment_cycle: CustomerCycleOrder['shipment_cycle'];
}) => {
  if (type === OrderType.shipment) {
    if (shipment_cycle) {
      return getEditPath('/members-club/cycle-orders', id);
    }

    return getEditPath('/orders/sales-orders', id);
  }
  return '';
};

const normalizeAllOpenOrders = (orders?: CustomerCycleOrder[]) => {
  if (!orders) return [];

  const allOpenOrders = orders.map(({ id, number, type, shipment_cycle }) => ({
    id,
    number,
    orderLink: getOrderLink({ id, shipment_cycle, type }),
  }));

  return allOpenOrders;
};

export const useCustomerOrders = ({
  data,
  customerId,
  paymentStatus,
  enabled = true,
  enabledAllOpenOrders = false,
}: {
  data?: CustomerOrderBody;
  paymentStatus?: OrderPaymentStatus;
  customerId?: string;
  enabled?: boolean;
  enabledAllOpenOrders?: boolean;
}) => {
  const { data: orders, isLoading } = useApiQuery(
    ['customerOrders', customerId],
    () =>
      ShipmentSalesOrdersService.getCustomerOrders({
        data,
        customer_id: customerId,
        ...(paymentStatus
          ? { params: { payment_statuses: [paymentStatus] } }
          : {}),
      }),
    { enabled },
  );

  const {
    data: allOpenOrders,
    isLoading: isLoadingAllOpenOrders,
    refetch: refetchAllOpenOrders,
  } = useApiQuery(
    ['allCustomerOpenOrders', customerId],
    () =>
      ShipmentSalesOrdersService.getCustomerOrders({
        customer_id: customerId,
        params: {
          payment_statuses: [OrderPaymentStatus.UNPAID],
          statuses: [OrderStatus.OPEN],
        },
      }),
    {
      enabled: enabledAllOpenOrders,
    },
  );

  return {
    orders,
    allOpenOrders: normalizeAllOpenOrders(allOpenOrders?.items),
    isLoading,
    isLoadingAllOpenOrders,
    refetchAllOpenOrders,
  };
};
