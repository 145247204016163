import { useState } from 'react';
import {
  Collapse,
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
  Grid,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { CreateSku, InventoryType, taxRateOptions } from 'shared';
import { BOTTLE_SIZE, VARIETAL } from '../../../constants';

type PropType = {
  form: UseFormReturnType<CreateSku, (values: CreateSku) => CreateSku>;
  margin: string;
  skuNumber?: string;
};

export const SpecificInformation = ({ form, margin, skuNumber }: PropType) => {
  const [price, setPrice] = useState(form.values.btg?.btg_price);
  const { values } = form;
  const { specific_information, saved_as_draft, inventory_type, is_kit } =
    values;

  const isAvailableByGlass = specific_information?.is_available_by_glass;
  const isAvailableOnlyByGlass =
    specific_information?.is_available_only_by_glass;

  const isDraft = saved_as_draft;
  const isAlcohol = inventory_type === InventoryType.ALCOHOL;
  const isKit = is_kit ?? false;
  const isDisabled = !isAvailableByGlass && !isAvailableOnlyByGlass;

  const glassesPerBottle = (specific_information?.glasses_per_bottle ??
    '') as '';

  const fullWidth = { width: '100%' };

  return (
    <Collapse in={isAlcohol && !isKit} transitionDuration={1000}>
      <Grid gutter="xl">
        <Grid.Col span={12}>
          <Text weight={600} size="lg">
            Specific Information
          </Text>
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            required={!isDraft && isAlcohol && !isKit}
            sx={fullWidth}
            label="ABV"
            {...form.getInputProps('specific_information.abv')}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <Select
            label="Bottle Size"
            required={!isDraft && isAlcohol && !isKit}
            sx={fullWidth}
            rightSectionWidth={30}
            {...form.getInputProps('specific_information.bottle_size')}
            data={BOTTLE_SIZE}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <Select
            label="Varietal"
            clearable
            sx={fullWidth}
            rightSectionWidth={30}
            {...form.getInputProps('specific_information.varietal')}
            data={VARIETAL}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <NumberInput
            hideControls
            required={!isDraft && isAlcohol && !isKit}
            label="Glasses per Bottle"
            sx={fullWidth}
            {...form.getInputProps('specific_information.glasses_per_bottle')}
            value={glassesPerBottle}
          />
        </Grid.Col>

        <Grid.Col span={12} pt={0}>
          <Switch
            label="Sample"
            labelPosition="right"
            sx={{ ...fullWidth, fontWeight: 600 }}
            {...form.getInputProps('specific_information.has_sample', {
              type: 'checkbox',
            })}
          />
        </Grid.Col>

        <Grid.Col span={6} pt={0}>
          <Switch
            label="BTG (available by the glass)"
            labelPosition="right"
            sx={{ ...fullWidth, fontWeight: 600 }}
            {...form.getInputProps(
              'specific_information.is_available_by_glass',
              {
                type: 'checkbox',
              },
            )}
            onChange={() => {
              if (isAvailableByGlass) {
                form.setFieldValue(
                  'specific_information.is_available_by_glass',
                  false,
                );
              } else {
                form.setFieldValue(
                  'specific_information.is_available_by_glass',
                  true,
                );
                form.setFieldValue(
                  'specific_information.is_available_only_by_glass',
                  false,
                );
              }
            }}
          />
        </Grid.Col>

        <Grid.Col span={6} pt={0}>
          <Switch
            label="BTG only (available only by the glass only)"
            labelPosition="right"
            sx={{ ...fullWidth, fontWeight: 600 }}
            {...form.getInputProps(
              'specific_information.is_available_only_by_glass',
              {
                type: 'checkbox',
              },
            )}
            onChange={() => {
              if (isAvailableOnlyByGlass) {
                form.setFieldValue(
                  'specific_information.is_available_only_by_glass',
                  false,
                );
              } else {
                form.setFieldValue(
                  'specific_information.is_available_only_by_glass',
                  true,
                );
                form.setFieldValue(
                  'specific_information.is_available_by_glass',
                  false,
                );
              }
            }}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="SKU"
            disabled
            placeholder={skuNumber ?? 'Will be displayed after saving'}
            variant="unstyled"
            sx={fullWidth}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <Select
            label="Tax Rate"
            disabled={isDisabled}
            sx={fullWidth}
            rightSectionWidth={30}
            {...form.getInputProps('btg.btg_tax_rate')}
            data={taxRateOptions}
          />
        </Grid.Col>

        <Grid.Col span={3}>
          <NumberInput
            hideControls
            required={!isDraft && isAlcohol && !isKit}
            label="Cost ($)"
            precision={2}
            disabled={isDisabled}
            sx={fullWidth}
            parser={(value) => value?.replace(/\$\s?|%\s?/g, '')}
            formatter={(value) => (value ? `$${value}` : '')}
            {...form.getInputProps('btg.btg_cost')}
            value={form.values.btg?.btg_cost ?? ''}
          />
        </Grid.Col>

        <Grid.Col span={3}>
          <TextInput
            required={!isDraft && isAlcohol && !isKit}
            label="Price ($ or %)"
            sx={fullWidth}
            disabled={isDisabled}
            {...form.getInputProps('btg.btg_price')}
            value={price}
            onChange={(event) => {
              const value = event?.currentTarget?.value.replace(/,/g, '.');
              setPrice(value);
              form.setFieldValue('btg.btg_price', value);
            }}
          />
        </Grid.Col>

        <Grid.Col span={6} pt={40}>
          <Grid gutter={'xl'}>
            <Grid.Col span={6}>
              <Text>Calculated Margin</Text>
            </Grid.Col>
            <Grid.Col span={'content'} ml="auto">
              <Text>{margin}</Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Collapse>
  );
};
