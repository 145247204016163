import { Link } from 'react-router-dom';
import { Button, Flex, Grid, Modal, Select, Switch, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { SingleValue } from 'react-select';

import { SelectAsync } from 'components';
import { useApiMutation, useEmployees, useToast } from 'hooks';
import {
  ClubMembershipChangeReason,
  CustomerOnHold,
  DATE_FORMAT_SLASH,
  formatDate,
  HALTER_RANCH_LOCATION_ID,
  OnHoldType,
  SAVE_BTN,
  SelectOption,
} from 'shared';
import { freezeMembershipSchema } from './freezeMembershipSchema';
import { CustomerService } from 'App/api';

const initialValues = {
  start_date: null,
  end_date: null,
  reason: '',
  source: {
    id: '',
  },
  notify: true,
  type: OnHoldType.FREEZE_ALL,
  freeze_all: true,
  freeze_cycles: false,
  exclude_cycles: false,
} as unknown as CustomerOnHold;

type Props = {
  initialOnHold?:
    | (CustomerOnHold & { defaultOnHoldSource?: SelectOption })
    | null;
  allOpenOrders: {
    id: string;
    number: string;
    orderLink: string;
  }[];
  customerId: string;
  opened: boolean;
  hasOpenOrders: boolean;
  close: () => void;
  refetch: () => void;
  refetchAllOpenOrders: () => void;
};

export const getTypeFreezeMembership = ({
  onHoldType,
  setValues,
}: {
  onHoldType: OnHoldType;
  setValues: (path: string, value: boolean | string) => void;
}) => {
  if (onHoldType === OnHoldType.FREEZE_ALL) {
    setValues('type', OnHoldType.FREEZE_ALL);

    setValues(OnHoldType.FREEZE_ALL, true);

    setValues(OnHoldType.FREEZE_CYCLES, false);
    setValues(OnHoldType.EXCLUDE_CYCLES, false);
  }
  if (onHoldType === OnHoldType.FREEZE_CYCLES) {
    setValues('type', OnHoldType.FREEZE_CYCLES);

    setValues(OnHoldType.FREEZE_CYCLES, true);

    setValues(OnHoldType.FREEZE_ALL, false);
    setValues(OnHoldType.EXCLUDE_CYCLES, false);
  }
  if (onHoldType === OnHoldType.EXCLUDE_CYCLES) {
    setValues('type', OnHoldType.EXCLUDE_CYCLES);

    setValues(OnHoldType.EXCLUDE_CYCLES, true);

    setValues(OnHoldType.FREEZE_ALL, false);
    setValues(OnHoldType.FREEZE_CYCLES, false);
  }
};

export const FreezeMembership = ({
  initialOnHold,
  allOpenOrders,
  customerId,
  opened,
  hasOpenOrders,
  close,
  refetch,
  refetchAllOpenOrders,
}: Props) => {
  const form = useForm({
    initialValues: initialOnHold ?? initialValues,
    validate: yupResolver(freezeMembershipSchema),
    validateInputOnBlur: true,
  });

  const { onSubmit, setFieldValue, isValid, reset, values } = form;
  const handleOnClose = () => {
    close();
    reset();
  };

  const addToast = useToast();

  const { mutate, isLoading: isSettingCustomerOnHold } = useApiMutation(
    ['customerOnHold'],
    CustomerService.setCustomerOnHold,
    {
      onSuccess: () => {
        addToast.success('Customer membership frozen successfully');
        handleOnClose();
        refetch();
      },
    },
  );

  const { mutate: update, isLoading: isUpdatingCustomerOnHold } =
    useApiMutation(
      ['updateCustomerOnHold'],
      CustomerService.updateCustomerOnHold,
      {
        onSuccess: () => {
          addToast.success('Customer membership updated successfully!');
          handleOnClose();
          refetch();
        },
      },
    );

  const { mutate: removeOnHold, isLoading: isRemovingCustomerOnHold } =
    useApiMutation(
      ['removeCustomerOnHold'],
      CustomerService.removeCustomerOnHold,
      {
        onSuccess: () => {
          addToast.success('Customer membership unfrozen successfully!');
          handleOnClose();
          refetch();
        },
      },
    );

  const {
    employeesOptions,
    hasMore,
    isLoading,
    setPageSelectAsync,
    setSearchStringSelectAsync,
  } = useEmployees({
    locationId: HALTER_RANCH_LOCATION_ID,
    page_size: 100,
    isActive: true,
    isSelectAsync: true,
    noCache: true,
  });

  const isExcludeCycles = values.type === OnHoldType.EXCLUDE_CYCLES;

  const currentDate = new Date();
  const { defaultOnHoldSource, start_date } = initialOnHold ?? {};

  const isActive = (start_date as Date) <= currentDate;

  const handleSubmit = () => {
    const payload = {
      id: customerId,
      data: {
        ...values,
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
      },
    };

    initialOnHold ? update(payload) : mutate(payload);
  };

  const processingCustomerOnHold =
    isSettingCustomerOnHold || isUpdatingCustomerOnHold;

  if (hasOpenOrders && !initialOnHold)
    return (
      <Modal
        size={588}
        opened={opened}
        onClose={() => {
          handleOnClose();
          refetchAllOpenOrders();
        }}
        title={
          <Text size="sm" weight={600}>
            The customer has open orders!
          </Text>
        }
      >
        <Text mb={5}>
          You must close all orders before you freeze your membership in the
          club.
        </Text>
        <Flex gap={10}>
          {allOpenOrders.map((order) => (
            <Text key={order.id}>
              <Link to={order.orderLink} target="_blank">
                #{order.number}
              </Link>
            </Text>
          ))}
        </Flex>
      </Modal>
    );

  return (
    <Modal
      size={588}
      opened={opened}
      onClose={handleOnClose}
      title={
        <Text size="sm" weight={600}>
          Freeze Membership
        </Text>
      }
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid gutter="xl">
          <Grid.Col>
            <Text size="sm" align="center">
              You are about to freeze Club Membership Tier and Club Add-On.
            </Text>
          </Grid.Col>

          <Grid.Col span={4}>
            <Switch
              label="Freeze All"
              labelPosition="right"
              data-testid="freeze-all-switch"
              {...form.getInputProps(OnHoldType.FREEZE_ALL, {
                type: 'checkbox',
              })}
              onChange={(e) => {
                if (!e.currentTarget.checked) return;

                getTypeFreezeMembership({
                  onHoldType: OnHoldType.FREEZE_ALL,
                  setValues: setFieldValue,
                });
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              label="Freeze Cycles"
              labelPosition="right"
              data-testid="freeze-cycles-switch"
              {...form.getInputProps(OnHoldType.FREEZE_CYCLES, {
                type: 'checkbox',
              })}
              onChange={(e) => {
                if (!e.currentTarget.checked) return;

                getTypeFreezeMembership({
                  onHoldType: OnHoldType.FREEZE_CYCLES,
                  setValues: setFieldValue,
                });
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              label="Exclude Cycles"
              labelPosition="right"
              data-testid="exclude-cycles-switch"
              {...form.getInputProps(OnHoldType.EXCLUDE_CYCLES, {
                type: 'checkbox',
              })}
              onChange={(e) => {
                if (!e.currentTarget.checked) return;

                getTypeFreezeMembership({
                  onHoldType: OnHoldType.EXCLUDE_CYCLES,
                  setValues: setFieldValue,
                });
              }}
            />
          </Grid.Col>

          <Grid.Col>
            <Select
              required
              label="Reason"
              data-testid="reason-dropdown"
              data={Object.values(ClubMembershipChangeReason)}
              {...form.getInputProps('reason')}
            />
          </Grid.Col>

          {!isExcludeCycles && (
            <>
              <Grid.Col>
                <DatePickerInput
                  required
                  label="Start Date"
                  data-testid="start-date-datepicker"
                  disabled={isActive}
                  valueFormat={DATE_FORMAT_SLASH}
                  excludeDate={(date) => date < currentDate}
                  {...form.getInputProps('start_date')}
                  onChange={(e) => {
                    setFieldValue('end_date', null);
                    form.setFieldValue('start_date', e as unknown as string);
                  }}
                />
              </Grid.Col>
              <Grid.Col>
                <DatePickerInput
                  required
                  label="End Date"
                  data-testid="end-date-datepicker"
                  disabled={!form.values.start_date}
                  valueFormat={DATE_FORMAT_SLASH}
                  excludeDate={(date) =>
                    date < (form.values.start_date as unknown as Date)
                  }
                  {...form.getInputProps('end_date')}
                />
              </Grid.Col>
            </>
          )}

          <Grid.Col>
            <SelectAsync
              required
              isSearchable
              label="Customer Source"
              defaultValue={defaultOnHoldSource}
              setPage={setPageSelectAsync}
              hasMore={hasMore}
              isLoading={isLoading}
              options={employeesOptions}
              {...form.getInputProps('source')}
              searchByString={({ search_string }) =>
                setSearchStringSelectAsync(search_string)
              }
              onChange={(item) => {
                const { value } = (item as SingleValue<SelectOption>) ?? {};
                if (!value) return;
                setFieldValue('source.id', value);
              }}
            />
          </Grid.Col>

          <Grid.Col>
            <Switch
              labelPosition="right"
              label="Automatic Email Notifications"
              data-testid="automatic-email-notifications-switch"
              {...form.getInputProps('notify', { type: 'checkbox' })}
            />
          </Grid.Col>

          <Grid.Col>
            <Button
              fullWidth
              type="submit"
              data-testid={SAVE_BTN}
              loading={processingCustomerOnHold}
              disabled={!isValid()}
            >
              Save
            </Button>
            {initialOnHold && (
              <Button
                fullWidth
                mt="md"
                variant="outline"
                data-testid="unfreeze-all-btn"
                loading={isRemovingCustomerOnHold}
                onClick={() => removeOnHold({ id: customerId })}
              >
                Unfreeze All
              </Button>
            )}
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
