import { useMemo } from 'react';
import { useSkus } from 'hooks';
import { getSelectOptions, getMargin, SkuStatus } from 'shared';
import { useCategories, useVendors } from '../../hooks';

type TPayload = {
  categoryId: string | null;
  price?: string | null;
  cost?: number | null;
  btgPrice?: string | null;
  btgCost?: number | null;
};

export const useInitialForm = (payload: TPayload) => {
  const { skuOptions, isLoading, setPage, updateFilters, hasMore } = useSkus({
    inputParams: { status: SkuStatus.Enabled },
  });
  const { categories, categoryOptions } = useCategories();
  const { options: vendorsOptions } = useVendors();
  const { categoryId, cost, price, btgCost, btgPrice } = payload;

  const subcategoryOptions = useMemo(() => {
    const category = categories.find((c) => categoryId === c.id);
    return getSelectOptions(category?.subcategories ?? []);
  }, [categories, categoryId]);

  const margin = useMemo(() => getMargin(price, cost), [cost, price]);

  const btgMargin = useMemo(
    () => getMargin(btgPrice, btgCost),
    [btgCost, btgPrice],
  );

  return {
    categories,
    categoryOptions,
    subcategoryOptions,
    vendorsOptions,
    margin,
    btgMargin,
    skuOptions,
    isLoadingSkus: isLoading,
    setPage,
    updateFilters,
    hasMore,
  };
};
