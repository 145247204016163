import { Notification, Text } from '@mantine/core';
import { CustomerError } from 'shared';

export const ErrorsNotification = ({
  errors,
}: {
  errors?: CustomerError[];
}) => {
  if (!errors?.length) return null;

  return (
    <Notification
      withCloseButton
      color="red"
      title="We notify you that"
      sx={{ margin: '20px auto' }}
    >
      {errors?.map(({ id, message, name }, index) => {
        const nameEntity = name ? `(${name})` : '';
        const errorMessage = `${index + 1} ${message} ${nameEntity}`;

        return (
          <Text key={`${id}${message}`} my={3}>
            {errorMessage}
          </Text>
        );
      })}
    </Notification>
  );
};
