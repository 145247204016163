import { OnHoldType, ShipmentCycleTabValue } from 'shared';
import { IconPointFilled } from '@tabler/icons-react';

export enum RequestAction {
  Approve = 'approved',
  Decline = 'declined',
}

export const ShipmentCycleTabTitle = {
  [ShipmentCycleTabValue.profile]: 'Profile',
  [ShipmentCycleTabValue.wallet]: 'Wallet',
  [ShipmentCycleTabValue.address]: 'Address',
  [ShipmentCycleTabValue.clubMember]: 'Club Member',
  [ShipmentCycleTabValue.orders]: 'Orders',
};

export const customerHoldOnType = {
  [OnHoldType.FREEZE_ALL]: 'Freeze All',
  [OnHoldType.FREEZE_CYCLES]: 'Freeze Cycles',
  [OnHoldType.EXCLUDE_CYCLES]: 'Exclude Cycles',
};

export const NONE_VALUE = 'None';
export const SHIPPING_ADDRESS = 'shipping address';
export const PICKUP_LOCATION = 'pickup location';
export const DELIVERY_METHOD = 'delivery method';
export const PAYMENT_METHOD = 'payment method';
export const DELETE_DEFAULT_ADDRESS_TEXT = `You have an upcoming order. If you press "Yes" and continue, the upcoming order will be updated to use the new default address.
If you press "No" the address will be deleted without making any changes to the upcoming order.`;

export const customerHintOptions = [
  {
    title: 'Change request',
    icon: <IconPointFilled size="20px" color="#F03E3E" />,
  },
  {
    title: 'Profile incomplete',
    icon: <IconPointFilled size="20px" color="#ffa94d" />,
  },
];
