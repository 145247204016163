import { Button, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import {
  ActionButtons,
  GeneralInfo,
  SelectAsync,
  ShipcompliantStatus,
  SummaryOrder,
} from 'components';
import {
  AsyncSelect,
  ClubMemberShip,
  getEmptyOption,
  GiftPackage,
  OrderPaymentStatus,
  SAVE_BTN,
  SelectOption,
  SubOrderInitialValues,
} from 'shared';
import { DeliveryInformation, InventoryDetails } from './suborderSection';
import { DefaultGiftPackage } from '../../types';
import { subOrderSchema } from './schema';

type Props = {
  initialValues?: SubOrderInitialValues;
  giftPackageOptions: SelectOption[];
  summary?: [string, string][];
  asyncSelectUsers?: AsyncSelect;
  giftPackage?: { [key: string]: GiftPackage };
  defaultGiftPackage?: DefaultGiftPackage;
  defaultClubMembership: ClubMemberShip | null;
  isLoadingUsers?: boolean;
  isComplianceCheckProcessing?: boolean;
  getClubMembershipByCustomer?: (id: string) => void;
  handleRecheckCompliance?: () => void;
  handleSubmit: (data: SubOrderInitialValues) => void;
};

export const SuborderForm = ({
  summary,
  initialValues,
  giftPackage,
  giftPackageOptions,
  defaultGiftPackage,
  defaultClubMembership,
  isLoadingUsers = false,
  isComplianceCheckProcessing = false,
  asyncSelectUsers,
  handleSubmit,
  handleRecheckCompliance,
  getClubMembershipByCustomer = () => null,
}: Props) => {
  const form = useForm({
    initialValues,
    validate: yupResolver(subOrderSchema),
    validateInputOnBlur: true,
  });

  const {
    values: { payment_status, compliance_status },
  } = form;

  const isPaid = payment_status === OrderPaymentStatus.PAID;

  const disabled = !form.isValid() || !form.isDirty() || isPaid;

  const summaryItems = summary?.map((item) => (
    <SummaryOrder key={item[0]} item={item} />
  ));

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {compliance_status && (
        <ShipcompliantStatus complianceStatus={compliance_status} />
      )}
      <GeneralInfo<SubOrderInitialValues>
        form={form}
        clubMemberShip={defaultClubMembership}
        selectCustomer={
          asyncSelectUsers && (
            <SelectAsync
              required
              isSearchable
              searchByString={({ search_string }) =>
                asyncSelectUsers.setSearchStringSelectAsync(search_string)
              }
              label="Customer Name"
              setPage={asyncSelectUsers.setPageSelectAsync}
              hasMore={asyncSelectUsers.hasMore}
              isLoading={isLoadingUsers}
              options={[
                getEmptyOption('New Recipient', 'null'),
                ...asyncSelectUsers.options,
              ]}
              {...form.getInputProps('customer_id')}
              onChange={(item) => {
                const { value } = item as SelectOption;
                if (value) {
                  getClubMembershipByCustomer(value);
                  form.setFieldValue('customer_id', value);
                }
              }}
            />
          )
        }
      />
      <Grid mb="xl" gutter="xl">
        <InventoryDetails
          form={form}
          summaryItems={summaryItems}
          giftPackage={giftPackage}
          giftPackageOptions={giftPackageOptions}
          defaultGiftPackage={defaultGiftPackage}
        />

        <DeliveryInformation form={form} />

        <Grid.Col span={12}>
          <ActionButtons
            type="submit"
            label="Save"
            data-testid={SAVE_BTN}
            disabled={disabled}
            secondary={
              handleRecheckCompliance && (
                <Button
                  fullWidth
                  variant="outline"
                  disabled={isPaid}
                  data-testid="resend-compliance-btn"
                  loading={isComplianceCheckProcessing}
                  onClick={handleRecheckCompliance}
                >
                  Resend for Compliance Check
                </Button>
              )
            }
          />
        </Grid.Col>
      </Grid>
    </form>
  );
};
