import {
  CreateDiscount,
  DATE_FORMAT_DOT,
  DiscountDto,
  formatDate,
  ROOT_CATEGORY_ID,
} from 'shared';

const getCategoryAndSubCategory = ({
  category,
}: {
  category: {
    id: string;
    name: string;
    parent: {
      id: string;
      name: string;
    };
  };
}) => {
  if (!category)
    return {
      category_id: null,
      subcategory_id: null,
    };

  if (category.parent.id === ROOT_CATEGORY_ID) {
    return {
      category_id: category.id,
      subcategory_id: null,
    };
  } else {
    return {
      category_id: category.parent.id,
      subcategory_id: category.id,
    };
  }
};

export const getInitialValues = (
  discount?: DiscountDto,
): CreateDiscount | undefined => {
  if (!discount) return;

  const {
    unit,
    name,
    value,
    comment,
    category,
    club_tier,
    locations,
    end_date,
    is_active,
    is_used,
    start_date,
    description,
    minimum_total,
    free_shipping,
    inventory_type,
    minimum_quantity,
    applied_to_glass,
    one_time_promotion,
    hide_from_ecommerce,
  } = discount;

  const { subcategory_id, category_id } = getCategoryAndSubCategory({
    category,
  });

  return {
    unit,
    name,
    value,
    comment,
    is_used,
    is_active,
    description,
    minimum_total,
    free_shipping,
    inventory_type,
    minimum_quantity,
    applied_to_glass,
    one_time_promotion,
    hide_from_ecommerce,
    category_id,
    subcategory_id,
    is_club_tier: !!club_tier,
    start_date: formatDate(start_date, DATE_FORMAT_DOT),
    end_date: formatDate(end_date, DATE_FORMAT_DOT),
    club_tier_id: club_tier?.id ?? null,
    location_ids: locations?.map(({ id }) => id) ?? null,
  };
};
