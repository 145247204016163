import {
  Header as MHeader,
  Text,
  MediaQuery,
  Group,
  Image,
  UnstyledButton,
  Menu,
} from '@mantine/core';
import { IconBell } from '@tabler/icons-react';
import { useSession } from 'App/session';
import { useCurrentEmployee } from 'hooks';
import Logo from 'assets/images/logo.png';
import { LocationSwitch } from '../locationSwitch';

const GROUP_STYLE = { height: '100%' };

export function Header() {
  const { signOut } = useSession();
  const { full_name } = useCurrentEmployee();
  return (
    <MHeader height={56} p={10} sx={{ zIndex: 101 }}>
      <Group sx={GROUP_STYLE} px={14} position="apart">
        <Group sx={GROUP_STYLE} align="center" spacing={10}>
          <Image
            data-testid="logo-loggedin"
            width={30}
            height={30}
            src={Logo}
          />
          <LocationSwitch />
        </Group>
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Group sx={GROUP_STYLE} align="center" spacing={10}>
            {/* <TextInput
              placeholder="Search"
              data-testid="header-search-input"
              icon={<IconSearch size={14} />}
            /> */}
            <IconBell stroke={1} />
            <Menu shadow="sm" width={200} position="bottom-end" zIndex={999}>
              <Menu.Target>
                <UnstyledButton data-testid="right-menu-button">
                  <Text weight={500}>{full_name}</Text>
                </UnstyledButton>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item data-testid="logout-button" onClick={signOut}>
                  Log Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </MediaQuery>
      </Group>
    </MHeader>
  );
}
