import { Link, useNavigate } from 'react-router-dom';

import { ContentLayout, BackButton } from 'components';
// import { useEditTiers } from 'hooks';
// import { Form } from '../form';

export const Edit = () => {
  const navigate = useNavigate();
  // const {} = useEditTiers();

  const title = 'Tiers';

  return (
    <ContentLayout
      title={title}
      processing={false}
      isLoading={false}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      {/* <Form /> */}
    </ContentLayout>
  );
};
