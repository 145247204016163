import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { employeeKey, useApiMutation } from 'hooks';
import { EmployeeFormValues, Employee, EmployeeStatus } from 'shared';
import { EmployeeService } from 'App/api';
import { getEditEmployeeData, getEmployeePayload } from '../helpers';

const updateEmployeeKey = 'updateEmployee';

export const useEditEmployee = ({
  data,
  onSuccess = () => null,
}: {
  data?: Employee;
  onSuccess: () => void;
}): {
  formData?: EmployeeFormValues;
  handleSubmit: (values: EmployeeFormValues) => void;
  disableEmployee: (v?: string) => void;
  isLoading: boolean;
} => {
  const employeeId = data?.id ?? '';
  const canBeActivated = data?.status !== EmployeeStatus.ACTIVE;

  const formData = useMemo(() => data && getEditEmployeeData(data), [data]);
  const client = useQueryClient();

  const { mutate: updateEmployee, isLoading: updating } = useApiMutation(
    [updateEmployeeKey, employeeId],
    EmployeeService.updateEmployee,
    {
      onSuccess: (data) => {
        onSuccess();
        client.setQueriesData([employeeKey, employeeId], data);
      },
    },
  );

  const { mutate: disableEmployee, isLoading: disabling } = useApiMutation(
    ['disableEmployee', employeeId],
    () => EmployeeService.disableEmployee(employeeId),
    {
      onSuccess,
    },
  );

  const { mutate: activateEmployee, isLoading: activating } = useApiMutation(
    ['disableEmployee', employeeId],
    () => EmployeeService.activateEmployee(employeeId),
  );

  const handleSubmit = ({ active, ...values }: EmployeeFormValues) => {
    if (canBeActivated && active) {
      activateEmployee({});
    }
    updateEmployee({
      employee_id: employeeId,
      data: getEmployeePayload(values),
    });
  };

  return {
    formData,
    handleSubmit,
    disableEmployee,
    isLoading: updating || disabling || activating,
  };
};
