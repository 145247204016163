import { reject, without } from 'rambda';
import { SingleValue } from 'react-select';
import { ActionIcon, Grid, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';

import { SelectAsync } from 'components';
import { useSkus } from 'hooks';
import {
  SelectOption,
  Suggestion,
  SuggestionsDto,
  setProductValue,
} from 'shared';

type Props = {
  form: UseFormReturnType<SuggestionsDto>;
  id?: string;
  skuId?: string;
  skuNumber?: string;
  skuProductName?: string;
  skuIds: (string | undefined)[];
  maxQuantity: number;
  canRemove: boolean;
};

export const Product = ({
  form,
  id,
  skuId,
  skuIds,
  skuNumber,
  skuProductName,
  maxQuantity,
  canRemove,
}: Props) => {
  const { skuOptions, isLoading, setPage, updateFilters, hasMore } = useSkus({
    inputParams: { has_inventory: true, is_alcohol: true },
  });

  const suggestions = form.values.suggestions;

  const rejectSkus = without([skuId], skuIds);
  const available = reject(
    ({ value }) => rejectSkus.includes(value),
    skuOptions,
  );

  const defaultValue = {
    value: skuId ?? '',
    label:
      skuNumber && skuProductName ? `[${skuNumber}] ${skuProductName}` : '',
  };

  return (
    <Grid gutter="xl" mb="xs">
      <Grid.Col span={7}>
        <SelectAsync
          required
          isSearchable
          searchByString={updateFilters}
          setPage={setPage}
          hasMore={hasMore}
          isLoading={isLoading}
          options={available}
          defaultValue={defaultValue}
          onChange={(item) => {
            const { value, label } = (item as SingleValue<SelectOption>) ?? {};
            if (!value || !label) return;

            const matches = label.match(/\[([^[\]]*)\]\s*([^[\]]*)/);

            const setSkuId = setProductValue<Suggestion>({
              arr: suggestions,
              key: id,
              value: {
                sku_id: value,
                sku_number: matches?.[1],
                sku_product_name: matches?.[2],
              },
            });

            form.setFieldValue('suggestions', setSkuId);
          }}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <NumberInput
          hideControls
          min={1}
          value={maxQuantity ?? ''}
          onChange={(e) => {
            if (!e) return;

            const setQuantity = setProductValue<Suggestion>({
              arr: suggestions,
              key: id,
              value: { max_quantity: e },
            });
            form.setFieldValue('suggestions', setQuantity);
          }}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <Grid align="center" justify="flex-end">
          <ActionIcon
            mt={12}
            disabled={!canRemove}
            onClick={() =>
              form.setFieldValue(
                'suggestions',
                form.values.suggestions.filter(
                  (i) => i.key !== id || i.sku_id !== skuId,
                ),
              )
            }
            data-testid="delete-button"
          >
            <IconTrash size={24} stroke={1} />
          </ActionIcon>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
