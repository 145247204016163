import { FC, ReactNode } from 'react';
import { TextInput, Container, Textarea, Select, Grid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import {
  CreatePartner,
  partnerTypeOptions,
  STATE_DROPDOWN,
  stateOptions,
} from 'shared';
import { validationSchema } from './validation';
import { MaskedInput, ActionButtons } from 'components';

export type Props = {
  handleSubmit: (values: CreatePartner) => void;
  initialValues: CreatePartner;
  children?: ReactNode;
};

export const Form: FC<Props> = ({
  handleSubmit,
  initialValues,
  children,
}): JSX.Element => {
  const form = useForm({
    initialValues,
    validate: yupResolver(validationSchema),
    validateInputOnBlur: true,
  });

  const fullWidth = { width: '100%' };

  return (
    <Container size={'xs'}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter={'xl'}>
          <Grid.Col>
            <TextInput
              required
              label="Company Name"
              {...form.getInputProps('company_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <Select
              required
              sx={fullWidth}
              label="Partner Type"
              data={partnerTypeOptions}
              {...form.getInputProps('type')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Website"
              {...form.getInputProps('website')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Street Address"
              {...form.getInputProps('street_address')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput required label="City" {...form.getInputProps('city')} />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              required
              searchable
              label="State"
              sx={fullWidth}
              data={stateOptions}
              data-testid={STATE_DROPDOWN}
              {...form.getInputProps('state')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MaskedInput
              required
              sx={fullWidth}
              testId="zipCode"
              label="Zip Code"
              mask="99999-9999"
              {...form.getInputProps('zip_code')}
            />
          </Grid.Col>
          <Grid.Col>
            <MaskedInput
              required
              testId="phoneNumber"
              label="Phone Number"
              mask="(999) 999-9999"
              {...form.getInputProps('phone_number')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              label="Primary Contact Name"
              {...form.getInputProps('primary_contact_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <Textarea
              sx={fullWidth}
              label="Comment"
              {...form.getInputProps('comment')}
            />
          </Grid.Col>
          <Grid.Col>
            <ActionButtons
              disabled={!form.isValid()}
              label="Save"
              aria-label="save"
              secondary={children}
            />
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
