import * as Yup from 'yup';

import { zipCodeRegex } from 'shared/validations/regex';
import {
  dateOfBirthValidator,
  phoneNumberValidator,
  requiredStringValidationRule,
} from 'shared';

const fieldNotRequired = Yup.string().nullable();

const getEmailPhoneDateOfBirth = (isEdit = false) => {
  return {
    email: isEdit
      ? requiredStringValidationRule('Email Address')
          .email('Please enter valid email address')
          .nullable()
      : Yup.string()
          .email('Please enter valid email address')
          .nullable()
          .notRequired(),

    phone_number: isEdit
      ? phoneNumberValidator
      : Yup.string().nullable().notRequired(),

    date_of_birth: isEdit
      ? dateOfBirthValidator.required('Date of Birth is required')
      : dateOfBirthValidator.notRequired(),
  };
};

export const addressStepSchema = Yup.object({
  address_name: fieldNotRequired,
  first_name: fieldNotRequired,
  last_name: fieldNotRequired,
  country: fieldNotRequired,
  state: fieldNotRequired,
  city: fieldNotRequired,
  business: fieldNotRequired,
  street_address_one: fieldNotRequired,
  street_address_two: fieldNotRequired,
  ...getEmailPhoneDateOfBirth(),
  zip_code: Yup.string().matches(zipCodeRegex, 'Invalid zip code.').nullable(),
  is_same_as_shipping_address: Yup.boolean(),
  is_default: Yup.boolean(),
});

export const addressEditSchema = Yup.object({
  address_name: fieldNotRequired,
  first_name: requiredStringValidationRule('First Name'),
  last_name: requiredStringValidationRule('Last Name'),
  country: requiredStringValidationRule('Country'),
  state: requiredStringValidationRule('State'),
  city: requiredStringValidationRule('City'),
  ...getEmailPhoneDateOfBirth(true),
  business: fieldNotRequired,
  street_address_one: requiredStringValidationRule('Street Address'),
  street_address_two: fieldNotRequired,
  zip_code: requiredStringValidationRule('Zip')
    .matches(zipCodeRegex, 'Invalid zip code.')
    .nullable(),
  is_same_as_shipping_address: Yup.boolean(),
  is_default: Yup.boolean(),
});
