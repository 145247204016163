import { useState } from 'react';
import cardValidator from 'card-validator';
import {
  Button,
  Container,
  Grid,
  Text,
  TextInput,
  Switch,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { openModal } from '@mantine/modals';
import { useCreditCardValidator, images } from 'react-creditcard-validator';

import { FIRST_NAME_INPUT, LAST_NAME_INPUT, TWalletForm } from 'shared';
import { AddressFields } from './AddressFields';
import { ConfirmData } from 'components';
import { getChangeInformationMessage } from '../../helpers';
import { PAYMENT_METHOD } from '../../constants';

export type Props = {
  isEdit?: boolean;
  processing?: boolean;
  isUnpaid?: boolean;
  handleSubmit?: (values: TWalletForm, update_orders?: boolean) => void;
  form: UseFormReturnType<TWalletForm>;
};

export const WalletForm = ({
  form,
  isEdit = false,
  isUnpaid = false,
  processing = false,
  handleSubmit,
}: Props): JSX.Element => {
  const [isErrorCardNumber, setIsErrorCardNumber] = useState(false);
  const [isErrorCvv, setIsErrorCvv] = useState(false);
  const {
    getCardNumberProps,
    getCardImageProps,
    getExpiryDateProps,
    meta: { erroredInputs },
  } = useCreditCardValidator();

  const fullWidth = { width: '100%' };

  const isValidCvv = cardValidator.cvv(form.values.cvv, 4).isPotentiallyValid;
  const isValidCardNumber = cardValidator.number(
    form.values.card_number.replace(/\s/g, ''),
  ).isValid;

  const cardErrorMessage = isErrorCardNumber && 'Invalid card number';
  const cvvErrorMessage = isErrorCvv && 'Invalid cvv';

  const isNotValidCard =
    !!erroredInputs.expiryDate || !isValidCvv || !isValidCardNumber;

  return (
    <Container size="sm">
      <form>
        <Grid gutter="xl">
          <Grid.Col>
            <TextInput
              required={isEdit}
              sx={fullWidth}
              label="Card Name"
              data-testid="card-name-input"
              {...form.getInputProps('card_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required={isEdit}
              label="Card Number"
              data-testid="cardNumber-input"
              {...getCardNumberProps({
                value: form.values.card_number,
                onChange: (e) =>
                  form.setFieldValue('card_number', e.currentTarget.value),
              })}
              size="sm"
              onBlur={() => setIsErrorCardNumber(!isValidCardNumber)}
              rightSection={<svg {...getCardImageProps({ images })} />}
              {...(isEdit ? { error: cardErrorMessage } : {})}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required={isEdit}
              data-testid="card-exp-input"
              label="Expiration Date"
              {...getExpiryDateProps({
                value: form.values.expiration_date,
                onChange: (e) =>
                  form.setFieldValue('expiration_date', e.currentTarget.value),
              })}
              size="sm"
              error={erroredInputs.expiryDate}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              required={isEdit}
              size="sm"
              data-testid="card-ccv-input"
              label="CVV"
              onChange={(e) => form.setFieldValue('cvv', e.currentTarget.value)}
              onBlur={() => setIsErrorCvv(!isValidCvv)}
              {...(isEdit ? { error: cvvErrorMessage } : {})}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required={isEdit}
              sx={fullWidth}
              data-testid="cardHolder-input"
              label="Name on Card"
              {...form.getInputProps('name_on_card')}
            />
          </Grid.Col>
          <Grid.Col>
            <Text size={18} weight={700}>
              Billing Address
            </Text>
            <TextInput
              required={isEdit}
              sx={fullWidth}
              data-testid={FIRST_NAME_INPUT}
              label="First Name"
              {...form.getInputProps('first_name')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required={isEdit}
              sx={fullWidth}
              data-testid={LAST_NAME_INPUT}
              label="Last Name"
              {...form.getInputProps('last_name')}
            />
          </Grid.Col>
          <AddressFields<TWalletForm> form={form} />
          {isEdit && (
            <Grid.Col>
              <Switch
                data-testid="set-default-switch"
                label="Set as Default"
                labelPosition="right"
                sx={{ fontWeight: 600 }}
                {...form.getInputProps('set_as_default', { type: 'checkbox' })}
              />
            </Grid.Col>
          )}
        </Grid>
      </form>
      {isEdit && handleSubmit && (
        <Container size="xs" my="xl">
          <Button
            fullWidth
            type="submit"
            data-testid="save-btn"
            aria-label="save"
            loading={processing}
            disabled={!form.isValid() || isNotValidCard}
            onClick={() => {
              if (isUnpaid && form.values.set_as_default) {
                return openModal({
                  size: 'lg',
                  title: (
                    <Text size="md" weight={600}>
                      Update Upcoming Order?
                    </Text>
                  ),
                  children: (
                    <ConfirmData
                      message={getChangeInformationMessage(PAYMENT_METHOD)}
                      approve={() => handleSubmit(form.values, true)}
                      reject={() => handleSubmit(form.values)}
                    />
                  ),
                });
              }
              handleSubmit(form.values);
            }}
          >
            Save
          </Button>
        </Container>
      )}
    </Container>
  );
};
