import { ActionIcon, Grid, NumberInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { CreateCulinarySku } from 'shared';

type Props = {
  form: UseFormReturnType<CreateCulinarySku>;
  index: number;
  modifierIndex: number;
};

export const VariantItem = ({ form, index, modifierIndex }: Props) => {
  const item = `specific_information.items.${modifierIndex}.variants.${index}`;
  const items = form.values.specific_information?.items;

  const canRemove = items && items[modifierIndex].variants.length > 2;

  const fullWidth = { width: '100%' };

  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={6}>
        <TextInput
          required
          sx={fullWidth}
          label="Variant Name"
          {...form.getInputProps(`${item}.name`)}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Grid gutter={'xl'} grow align={'center'}>
          <Grid.Col span={10}>
            <NumberInput
              hideControls
              precision={2}
              sx={fullWidth}
              label="Price ($) (Empty for free)"
              {...form.getInputProps(`${item}.price`)}
              value={items?.[modifierIndex].variants[index].price ?? ''}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <ActionIcon
              mt="xl"
              ml={'auto'}
              disabled={!canRemove}
              onClick={() =>
                form.removeListItem(
                  `specific_information.items.${modifierIndex}.variants`,
                  index,
                )
              }
            >
              <IconTrash size={24} stroke={1} />
            </ActionIcon>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
