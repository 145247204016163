/* eslint-disable react/display-name */
import { useState, forwardRef, useCallback, useRef, FocusEvent } from 'react';
import {
  Text,
  Select,
  SelectProps,
  SelectItem as OptionType,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { includes, or } from 'rambda';

import { useApiQuery } from 'hooks';
import { SkuService } from 'App/api';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
      <Text size="xs" opacity={0.65}>
        SKU#: {description}
      </Text>
    </div>
  ),
);

export const SkuSearch = ({
  onBlur = () => null,
  ...props
}: Omit<SelectProps, 'data'>) => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 300);

  useApiQuery(
    ['search-skus', debounced],
    () =>
      SkuService.getCollection({
        page_size: 100,
        search_string: debounced,
      }),
    {
      enabled: debounced.length > 1,
      onSuccess: (data) =>
        setOptions(
          data.items.map(({ id, product_name, sku_number }) => ({
            value: id,
            label: `${product_name}`,
            description: sku_number || '',
          })),
        ),
    },
  );

  const handleChange = useRef(props.onChange).current as (v: string) => void;
  const currentValue = props.value;

  const onChange = useCallback(
    (v: string) => {
      if (v) {
        const selected = options.find(({ value: val }) => val === v);
        setSearchValue(selected?.label ?? '');
        handleChange(v);
      }
    },
    [handleChange, options],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement, Element>) => {
      const selected = options.find(({ value: val }) => val === currentValue);
      if (!selected) {
        handleChange('');
      }
      onBlur(e);
    },
    [currentValue, handleChange, onBlur, options],
  );

  return (
    <Select
      {...props}
      placeholder="Search by name"
      itemComponent={SelectItem}
      searchable
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      nothingFound="No options"
      data={options}
      filter={(value, { label, description }) =>
        or(includes(value, label as string), includes(value, description))
      }
      onChange={onChange}
      onBlur={handleBlur}
    />
  );
};
