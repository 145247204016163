import { PosSalesOrderDto, PosSalesPaymentType } from 'shared';
import { GuestAndItemOrder } from './GuestAndItemOrder';
import { OtherAndWholeOrder } from './OtherAndWholeOrder';
import { OrderProps } from './propsType';

interface Props
  extends Omit<OrderProps, 'orderId' | 'orderNumber' | 'orderStatus'> {
  order: PosSalesOrderDto;
}

export const Order = ({
  order,
  payment,
  isRefunding,
  generationPDFPaymentIds,
  handleRefund,
  handleSendReceipt,
  handleGeneratePartialReceipt,
}: Props) => {
  const { pos_payment_type, id, number, payment_status } = order;
  const isWholeAndOther = [
    PosSalesPaymentType.OTHER,
    PosSalesPaymentType.WHOLE_TICKET,
  ].includes(pos_payment_type);

  const isGuestAndItem = [
    PosSalesPaymentType.PER_GUEST,
    PosSalesPaymentType.PER_ITEM,
  ].includes(pos_payment_type);

  if (isWholeAndOther)
    return (
      <OtherAndWholeOrder
        orderId={id}
        orderNumber={number}
        orderStatus={payment_status}
        payment={payment}
        isRefunding={isRefunding}
        generationPDFPaymentIds={generationPDFPaymentIds}
        handleRefund={handleRefund}
        handleSendReceipt={handleSendReceipt}
        handleGeneratePartialReceipt={handleGeneratePartialReceipt}
      />
    );

  if (isGuestAndItem)
    return (
      <GuestAndItemOrder
        orderId={id}
        orderNumber={number}
        orderStatus={payment_status}
        payment={payment}
        isRefunding={isRefunding}
        generationPDFPaymentIds={generationPDFPaymentIds}
        handleRefund={handleRefund}
        handleSendReceipt={handleSendReceipt}
        handleGeneratePartialReceipt={handleGeneratePartialReceipt}
      />
    );

  return <></>;
};
